
//fetch mails
export const FETCH_MAIL_BEGIN = 'FETCH_MAIL_BEGIN'
export const FETCH_MAIL_SUCCESS = 'FETCH_MAIL_SUCCESS'
export const FETCH_MAIL_FAILURE = 'FETCH_MAIL_FAILURE'

export const SET_SELECTED_MAIL = 'SET_SELECTED_MAIL'
export const RESET_SELECT_MAILS = 'RESET_SELECT_MAILS'
export const SELECT_MAIL = 'SELECT_MAIL'
export const SELECT_ALL_MAIL = 'SELECT_ALL_MAIL'

export const SEND_MAIL_BEGIN = 'SEND_MAIL_BEGIN'
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS'
export const SEND_MAIL_FAILURE = 'SEND_MAIL_FAILURE'

export const SAVE_DRAFT_BEGIN = 'SAVE_DRAFT_BEGIN'
export const SAVE_DRAFT_SUCCESS = 'SAVE_DRAFT_SUCCESS'
export const SAVE_DRAFT_FAILURE = 'SAVE_DRAFT_FAILURE'

export const UPDATE_MAIL_BEGIN = 'UPDATE_MAIL_BEGIN'
export const UPDATE_MAIL_SUCCESS = 'UPDATE_MAIL_SUCCESS'
export const UPDATE_MAIL_FAILURE = 'UPDATE_MAIL_FAILURE'

export const UPDATE_MAIL_LABEL_BEGIN = 'UPDATE_MAIL_LABEL_BEGIN'
export const UPDATE_MAIL_LABEL_SUCCESS = 'UPDATE_MAIL_LABEL_SUCCESS'
export const UPDATE_MAIL_LABEL_FAILURE = 'UPDATE_MAIL_LABEL_FAILURE'

export const FETCH_ALL_MAIL_BEGIN = 'FETCH_ALL_MAIL_BEGIN'
export const FETCH_ALL_MAIL_SUCCESS = 'FETCH_ALL_MAIL_SUCCESS'
export const FETCH_ALL_MAIL_FAILURE = 'FETCH_ALL_MAIL_FAILURE'

export const FETCH_MAIL_READ_COUNT_BEGIN = 'FETCH_MAIL_READ_COUNT_BEGIN'
export const FETCH_MAIL_READ_COUNT_SUCCESS = 'FETCH_MAIL_READ_COUNT_SUCCESS'
export const FETCH_MAIL_READ_COUNT_FAILURE = 'FETCH_MAIL_READ_COUNT_FAILURE'

export const FETCH_ALL_EMAIL_ADDRESS_BEGIN = 'FETCH_ALL_EMAIL_ADDRESS_BEGIN'
export const FETCH_ALL_EMAIL_ADDRESS_SUCCESS = 'FETCH_ALL_EMAIL_ADDRESS_SUCCESS'
export const FETCH_ALL_EMAIL_ADDRESS_FAILURE = 'FETCH_ALL_EMAIL_ADDRESS_FAILURE'

export const fetchMailBegin = () => ({
    type: FETCH_MAIL_BEGIN
})

export const fetchMailSuccess = mails => ({
    type: FETCH_MAIL_SUCCESS,
    payload: { mails }
})

export const fetchMailFailure = error => ({
    type: FETCH_MAIL_FAILURE,
    payload: { error }
})

export const setSelectedMail = mail => ({
    type: SET_SELECTED_MAIL,
    payload: { mail }
})

export const resetSelectedMail = () => ({
    type: RESET_SELECT_MAILS
})

export const selectMail = id => ({
    type: SELECT_MAIL,
    payload: { id }
})

export const selectAllMail = val => ({ 
    type: SELECT_ALL_MAIL, 
    payload: { val } 
})

export const sendMailBegin = () => ({
    type: SEND_MAIL_BEGIN
})

export const sendMailSuccess = mail => ({
    type: SEND_MAIL_SUCCESS,
    payload: { mail }
})

export const sendMailFailure = error => ({
    type: SEND_MAIL_FAILURE,
    payload: { error }
})

export const saveDraftBegin = () => ({
    type: SAVE_DRAFT_BEGIN
})

export const saveDraftSuccess = mail => ({
    type: SAVE_DRAFT_SUCCESS,
    payload: { mail }
})

export const saveDraftFailure = error => ({
    type: SAVE_DRAFT_FAILURE,
    payload: { error }
})

export const updateMailsBegin = () => ({
    type: UPDATE_MAIL_BEGIN
})

export const updateMailsSuccess = mail => ({
    type: UPDATE_MAIL_SUCCESS,
    payload: { mail }
})

export const updateMailsFailure = error => ({
    type: UPDATE_MAIL_FAILURE,
    payload: { error }
})

export const updateMailLabelBegin = () => ({
    type: UPDATE_MAIL_LABEL_BEGIN
})

export const updateMailLabelSuccess = mail => ({
    type: UPDATE_MAIL_LABEL_SUCCESS,
    payload: { mail }
})

export const updateMailLabelFailure = error => ({
    type: UPDATE_MAIL_LABEL_FAILURE,
    payload: { error }
})

export const fetchAllMailBegin = () => ({
    type: FETCH_ALL_MAIL_BEGIN
})

export const fetchAllMailSuccess = mails => ({
    type: FETCH_ALL_MAIL_SUCCESS,
    payload: { mails }
})

export const fetchAllMailFailure = error => ({
    type: FETCH_ALL_MAIL_FAILURE,
    payload: { error }
})

export const fetchMailReadCountBegin = () => ({
    type: FETCH_MAIL_READ_COUNT_BEGIN
})

export const fetchMailReadCountSuccess = mails => ({
    type: FETCH_MAIL_READ_COUNT_SUCCESS,
    payload: { mails }
})

export const fetchMailReadCountFailure = error => ({
    type: FETCH_MAIL_READ_COUNT_FAILURE,
    payload: { error }
})

export const fetchAllEmailAddressBegin = () => ({
    type: FETCH_ALL_EMAIL_ADDRESS_BEGIN
})

export const fetchAllEmailAddressSuccess = mails => ({
    type: FETCH_ALL_EMAIL_ADDRESS_SUCCESS,
    payload: { mails }
})

export const fetchAllEmailAddressFailure = error => ({
    type: FETCH_ALL_EMAIL_ADDRESS_FAILURE,
    payload: { error }
})