
import * as ACTIONS from '../../actions/reports/index';

const initialState = {
    CourierInward: [],
    loading: false,
    error: ''
}

const CourierInward = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_COURIER_INWARD_BEGIN:
            return {
                ...state,
                CourierInward: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_COURIER_INWARD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                CourierInward: action.payload.data
            }

        case ACTIONS.FETCH_COURIER_INWARD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default CourierInward;