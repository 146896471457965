//clients
export const FETCH_CLIENTS_BEGIN = 'FETCH_CLIENTS_BEGIN'
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS'
export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE'

export const fetchClientsBegin = () => ({
    type: FETCH_CLIENTS_BEGIN
})

export const fetchClientsSuccess = data => ({
    type: FETCH_CLIENTS_SUCCESS,
    payload: { data }
})

export const fetchClientFailure = error => ({
    type: FETCH_CLIENTS_FAILURE,
    payload: { error }
})


export const FETCH_INCOMPLETE_BEGIN = 'FETCH_INCOMPLETE_BEGIN'
export const FETCH_INCOMPLETE_SUCCESS = 'FETCH_INCOMPLETE_SUCCESS'
export const FETCH_INCOMPLETE_FAILURE = 'FETCH_INCOMPLETE_FAILURE'

export const fetchIncompleteBegin = () => ({
    type: FETCH_INCOMPLETE_BEGIN
})

export const fetchIncompleteSuccess = data => ({
    type: FETCH_INCOMPLETE_SUCCESS,
    payload: { data }
})

export const fetchIncompleteFailure = error => ({
    type: FETCH_INCOMPLETE_FAILURE,
    payload: { error }
})


export const UPDATE_CLIENTS_BEGIN = 'UPDATE_CLIENTS_BEGIN'
export const UPDATE_CLIENTS_SUCCESS = 'UPDATE_CLIENTS_SUCCESS'
export const UPDATE_CLIENTS_FAILURE = 'UPDATE_CLIENTS_FAILURE'

export const updateClientsBegin = () => ({
    type: UPDATE_CLIENTS_BEGIN
})

export const updateClientsSuccess = data => ({
    type: UPDATE_CLIENTS_SUCCESS,
    payload: { data }
})

export const updateClientFailure = error => ({
    type: UPDATE_CLIENTS_FAILURE,
    payload: { error }
})