// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
// import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import CTCL from '@src/views/pages/CTCL/store/reducer'
import IPO from '@src/views/pages/IPO/store/reducer'
import clients from './clients'

import branches from '../reducers/hrm/branches';
import exchanges from '../reducers/reports/exchanges';
import reports from '../reducers/reports/reports';
import designation from './hrm/designation';
import department from './hrm/department';
import states from './hrm/state';
import city from './hrm/city';
import empId from './reports/EmpId';
import employee from './hrm/employee';
import employeeById from './hrm/employeeById';
import employeeBankDetails from './hrm/employeeBankDetails';
import holiday from './hrm/holiday';
import bank from './hrm/bank';
import task from './hrm/task';
import followuptask from './hrm/followUpTask';
import ipaddress from './hrm/ipaddress';
import taskhistory from './hrm/taskhistory';
import ipo from './ipo';
import AccountOffers from './hrm/accountoffers';
import UsefulLink from './hrm/UsefulLink';
import designationbydepartymentid from './hrm/designationbydepartymentid';
import ClientCode from './hrm/ClientCode';
import Email from './hrm/email';
import SOP from './hrm/SOP';
import SOPCategory from './hrm/SOPcategory';
import SOPRecentPost from './hrm/SOPRecentpost';
import Contact from './hrm/contact';
import ReverseClient from './hrm/ReverseClient';
import RMSTTransfer from './hrm/RMSTTranfer';
import RMSTTranferDownloadFile from './hrm/RMSTTranferDownloadFile';
import RMS from './reports/rms';
import Organization from './hrm/organization'
import ClientDatabase from './reports/client-database';
import Compliance from './reports/compliance';
import Vacancy from './hrm/vacancy'
import VacancyCareer from './hrm/vacancycareer'
import DealerKey from './hrm/dealerkey'
import PackagePartner from './hrm/PackagePartner'
import PackageProduct from './hrm/PackageProduct'
import PackageTags from './hrm/PackageTags'
import PackageMaster from './hrm/PackageMaster'
import ClientSubscription from './hrm/clientsubscription'
import mail from './mail';
import Plan from './hrm/plan'
import Validity from './hrm/Validity'
import PackagePrice from './hrm/PackagePrice'
import Module from './hrm/Module'
import EmployeeModule from './hrm/employeemodule'
import DepartmentModule from './hrm/DepartmentModule'
import FormCategory from './hrm/FormCategory'
import FormMaster from './hrm/FormMaster'
import ClientUser from './hrm/ClientUser'
import ClientSubscriptionById from './hrm/clientsubscriptionbyid'
import ClientImage from './hrm/ClientImage'
import SegmentRequest from './hrm/SegmentRequest'
import ClientDatabaseBank from './hrm/ClientDatabaseBank'
import ClientNominee from './hrm/ClientNominee'
import AddClientBank from './hrm/AddClientBank'
import ChangeDemate from './hrm/ChangeDemate'
import ChangeAddress from './hrm/ChangeAddress'
import ChangeEmailMobile from './hrm/ChangeEmailMobile'
import EKYCClientCode from './hrm/EKYCClientCode'
import Bookmarked from './hrm/bookmarked'
import KYCRemark from './hrm/KYCRemark'
import Leave from './hrm/Leave'
import CourierInward from './hrm/CourierInward'
import CourierOutward from './hrm/CourierOutward'
import CourierNameList from './hrm/CourierNameList'
import CourierType from './hrm/CourierType'
import CourierSender from './hrm/CourierSender'
import TenderOrder from './hrm/TenderOrder'
import ncborder from './hrm/ncborder'
import IPOLoginErrorLog from './hrm/IPOLoginErrorLog'
import IPOAccordLogs from './hrm/IPOAccordLogs'
import Notes from './hrm/Notes'
import DigitalDocsFile from './hrm/DigitalDocsFile'
import DigitalDocs from './hrm/DigitalDocs'
import Calendar from './hrm/Calendar'
import BrainRecentDevice from './hrm/BrainRecentDevice'
import Menu from './hrm/Menu'
import EmailTemplate from './hrm/EmailTemplate'
import EmailTemplateSubject from './hrm/EmailTemplateSubject'
import EmailTemplateTags from './hrm/EmailTemplateTags'
import BOSSRecentDevice from './hrm/BOSSRecentDevice'
import Notification from './hrm/Notification'
import AllNotification from './hrm/AllNotification'
import ReminderNotification from './hrm/ReminderNotification'
import Circular from './hrm/Circular';
import CircularCategory from './hrm/Circularcategory'
import CircularRecentPost from './hrm/CircularRecentpost'
import FinsetuNewsList from './hrm/FinsetuNewsList'
import FinsetuNewsTag from './hrm/FinsetuNewsTag'
import FinsetuNewsListing from './hrm/FinsetuNewsListing'
import InvestorGrouping from './hrm/InvestorGrouping'
import MenuMaster from './menu-master'
import BrokerageCharges from './hrm/BrokerageCharges'
import BrainClientProfile from './hrm/BrainClientProfile'
import BackOfficeLedger from './reports/backoffice-Ledger'
import BackOfficePosition from './reports/backoffice-Position'
import BackOfficeHolding from './reports/backoffice-Holding'


const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  // ecommerce,
  dataTables,
  branches,
  exchanges,
  reports,
  designation,
  department,
  states,
  city,
  empId,
  employee,
  employeeById,
  employeeBankDetails,
  holiday,
  bank,
  task,
  CTCL,
  IPO,
  ipo,
  clients,
  followuptask,
  ipaddress,
  taskhistory,
  AccountOffers,
  UsefulLink,
  designationbydepartymentid,
  ClientCode,
  Email,
  SOP,
  SOPCategory,
  SOPRecentPost,
  Contact,
  ReverseClient,
  RMSTTransfer,
  RMSTTranferDownloadFile,
  RMS,
  Organization,
  Vacancy,
  VacancyCareer,
  ClientDatabase,
  Compliance,
  DealerKey,
  PackagePartner,
  PackageProduct,
  PackageTags,
  PackageMaster,
  ClientSubscription,
  mail,
  Plan,
  Validity,
  PackagePrice,
  Module,
  EmployeeModule,
  DepartmentModule,
  FormCategory,
  FormMaster,
  ClientUser,
  ClientSubscriptionById,
  ClientImage,
  SegmentRequest,
  ClientDatabaseBank,
  ClientNominee,
  AddClientBank,
  ChangeDemate,
  ChangeAddress,
  ChangeEmailMobile,
  EKYCClientCode,
  Bookmarked,
  KYCRemark,
  Leave,
  CourierInward,
  CourierOutward,
  CourierNameList,
  CourierType,
  CourierSender,
  TenderOrder,
  ncborder,
  IPOLoginErrorLog,
  IPOAccordLogs,
  Notes,
  DigitalDocsFile,
  DigitalDocs,
  Calendar,
  BrainRecentDevice,
  Menu,
  EmailTemplate,
  EmailTemplateSubject,
  EmailTemplateTags,
  BOSSRecentDevice,
  Notification,
  AllNotification,
  ReminderNotification,
  Circular,
  CircularCategory,
  CircularRecentPost,
  FinsetuNewsList,
  FinsetuNewsTag,
  FinsetuNewsListing,
  InvestorGrouping,
  MenuMaster,
  BrokerageCharges,
  BrainClientProfile,
  BackOfficeLedger,
  BackOfficePosition,
  BackOfficeHolding,
})

export default rootReducer
