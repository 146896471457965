
import * as ACTIONS from '../../actions/ipo'

const initialState = {
    ipos: [],
    loading: false,
    error: '',
    applyresult: ''
}

const ipo = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_IPO_BEGIN:
            return {
                ...state,
                ipos: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_IPO_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                ipos: action.payload.data
            }

        case ACTIONS.FETCH_IPO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.APPLY_IPO_BEGIN:
            return {
                ...state,
                applyresult: '',
                loading: true,
                error: ''
            }

        case ACTIONS.APPLY_IPO_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                applyresult: action.payload.data
            }

        case ACTIONS.APPLY_IPO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default ipo;