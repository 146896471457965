
import * as ACTIONS from '../../actions/reports'

const initialState = {
    CourierSender: [],
    loading: false,
    error: ''
}

const CourierSender = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_COURIER_SENDER_BEGIN:
            return {
                ...state,
                CourierSender: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_COURIER_SENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                CourierSender: action.payload.data
            }

        case ACTIONS.FETCH_COURIER_SENDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default CourierSender;