
//fetch ipo
export const FETCH_IPO_BEGIN = 'FETCH_IPO_BEGIN'
export const FETCH_IPO_SUCCESS = 'FETCH_IPO_SUCCESS'
export const FETCH_IPO_FAILURE = 'FETCH_IPO_FAILURE'

export const fetchIPOsBegin = () => ({
    type: FETCH_IPO_BEGIN
})

export const fetchIPOsSuccess = ipos => ({
    type: FETCH_IPO_SUCCESS,
    payload: { ipos }
})

export const fetchIPOsFailure = error => ({
    type: FETCH_IPO_FAILURE,
    payload: { error }
})

//reapply ipo
export const APPLY_IPO_BEGIN = 'APPLY_IPO_BEGIN'
export const APPLY_IPO_SUCCESS = 'APPLY_IPO_SUCCESS'
export const APPLY_IPO_FAILURE = 'APPLY_IPO_FAILURE'

export const applyIPOsBegin = () => ({
    type: APPLY_IPO_BEGIN
})

export const applyIPOsSuccess = ipos => ({
    type: APPLY_IPO_SUCCESS,
    payload: { ipos }
})

export const applyIPOsFailure = error => ({
    type: APPLY_IPO_FAILURE,
    payload: { error }
})

export const ADD_IPOORDER_BEGIN = 'ADD_IPOORDER_BEGIN'
export const ADD_IPOORDER_SUCCESS = 'ADD_IPOORDER_SUCCESS'
export const ADD_IPOORDER_FAILURE = 'ADD_IPOORDER_FAILURE'

export const addIPOOrderBegin = () => ({
    type: ADD_IPOORDER_BEGIN
})

export const addIPOOrderSuccess = ipo => ({
    type: ADD_IPOORDER_SUCCESS,
    payload: { ipo }
})

export const addIPOOrderFailure = error => ({
    type: ADD_IPOORDER_FAILURE,
    payload: { error }
})

export const VALIDATE_VPA_BEGIN = 'VALIDATE_VPA_BEGIN'
export const VALIDATE_VPA_SUCCESS = 'VALIDATE_VPA_SUCCESS'
export const VALIDATE_VPA_FAILURE = 'VALIDATE_VPA_FAILURE'

export const validateVPABegin = () => ({
    type: VALIDATE_VPA_BEGIN
})

export const validateVPASuccess = response => ({
    type: VALIDATE_VPA_SUCCESS,
    payload: { response }
})

export const validateVPAFailure = error => ({
    type: VALIDATE_VPA_FAILURE,
    payload: { error }
})