// ** Initial State
const initialState = {
    selectedRow: {}
  }
  
  const CTCLReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SELECT_ROW':
        return { ...state, selectedRow: action.row }
      default:
        return state
    }
  }
  export default CTCLReducer
  