
import * as ACTIONS from '../../actions/reports'

const initialState = {
    CircularCategory: [],
    loading: false,
    error: ''
}

const CircularCategory = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_CIRCULAR_CATEGORY_BEGIN:
            return {
                ...state,
                CircularCategory: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_CIRCULAR_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                CircularCategory: action.payload.data
            }

        case ACTIONS.FETCH_CIRCULAR_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default CircularCategory;