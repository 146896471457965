//clients
export const FETCH_CLIENTDATABASE_BEGIN = 'FETCH_CLIENTDATABASE_BEGIN'
export const FETCH_CLIENTDATABASE_SUCCESS = 'FETCH_CLIENTDATABASE_SUCCESS'
export const FETCH_CLIENTDATABASE_FAILURE = 'FETCH_CLIENTDATABASE_FAILURE'

export const fetchClientDatabaseBegin = () => ({
    type: FETCH_CLIENTDATABASE_BEGIN
})

export const fetchClientDatabaseSuccess = data => ({
    type: FETCH_CLIENTDATABASE_SUCCESS,
    payload: { data }
})

export const fetchClientDatabaseFailure = error => ({
    type: FETCH_CLIENTDATABASE_FAILURE,
    payload: { error }
})