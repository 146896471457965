import * as ACTIONS from '../../actions/reports'

const initialState = {
    data: [],
    loading: false,
    error: '',
    holdingsData: [],
    positionData: []
}

const reports = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.REPORT_BEGIN:
            return {
                ...state,
                data: [],
                loading: true,
                error: ''
            }

        case ACTIONS.REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                data: action.payload.data
            }

        case ACTIONS.REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.HOLDINGS_REPORT_BEGIN:
            return {
                ...state,
                loading: true,
                holdingsData: [],
                error: ''
            }

        case ACTIONS.HOLDINGS_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                holdingsData: action.payload.data
            }

        case ACTIONS.HOLDINGS_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.POSITION_REPORT_BEGIN:
            return {
                ...state,
                loading: true,
                positionData: [],
                error: ''
            }

        case ACTIONS.POSITION_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                positionData: action.payload.data
            }

        case ACTIONS.POSITION_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default reports