
import * as ACTIONS from '../../actions/reports/index';

const initialState = {
    ChangeAddress: [],
    loading: false,
    error: ''
}

const ChangeAddress = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_CHANGE_ADDRESS_BEGIN:
            return {
                ...state,
                ChangeAddress: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_CHANGE_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                ChangeAddress: action.payload.data
            }

        default:
            return state
    }
}

export default ChangeAddress;