
import * as ACTIONS from '../../actions/reports/index';

const initialState = {
    Calendar: [],
    loading: false,
    error: ''
}

const Calendar = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_CALENDAR_BEGIN:
            return {
                ...state,
                Calendar: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_CALENDAR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                Calendar: action.payload.data
            }

        case ACTIONS.FETCH_CALENDAR_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default Calendar;