import * as ACTIONS from '../../actions/compliance'

const initialState = {
    quaterlysettlementdata: [],
    earlypayindata: [],
    physicaldata: [],
    peakfiledata: [],
    techexcelfiledata: [],
    loading: false,
    error: ''
}

const compliance = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_QUATERLYSETTLEMENT_BEGIN:
            return {
                ...state,
                quaterlysettlementdata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_QUATERLYSETTLEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                quaterlysettlementdata: action.payload.data
            }

        case ACTIONS.FETCH_QUATERLYSETTLEMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_EARLYPAYIN_BEGIN:
            return {
                ...state,
                earlypayindata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_EARLYPAYIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                earlypayindata: action.payload.data
            }

        case ACTIONS.FETCH_EARLYPAYIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_PHYSICAL_BEGIN:
            return {
                ...state,
                physicaldata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_PHYSICAL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                physicaldata: action.payload.data
            }

        case ACTIONS.FETCH_PHYSICAL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_PEAKFILE_BEGIN:
            return {
                ...state,
                peakfiledata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_PEAKFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                peakfiledata: action.payload.data
            }

        case ACTIONS.FETCH_PEAKFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_TECHEXCELFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                techexcelfiledata: action.payload.data
            }

        default:
            return state
    }
}

export default compliance;