import * as ACTIONS from '../../actions/clients'

const initialState = {
    list: [],
    loading: false,
    error: '',
    client: {},
    incompletelist: []
}

const clients = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_CLIENTS_BEGIN:
            return {
                ...state,
                list: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_CLIENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                list: action.payload.data
            }

        case ACTIONS.FETCH_CLIENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.UPDATE_CLIENTS_BEGIN:
            return {
                ...state,
                client: {},
                loading: true,
                error: ''
            }

        case ACTIONS.UPDATE_CLIENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                client: action.payload.data
            }

        case ACTIONS.UPDATE_CLIENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_INCOMPLETE_BEGIN:
            return {
                ...state,
                incompletelist: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_INCOMPLETE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                incompletelist: action.payload.data
            }

        case ACTIONS.FETCH_INCOMPLETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default clients