// import { fetchLedgersMethod } from '../api/index';



export const FETCH_EMPID_SUCCESS = 'FETCH_EMPID_SUCCESS'

export const fetchEmpIdSuccess = empId => 

({
    type: FETCH_EMPID_SUCCESS,
    payload: { empId }
})

//employess

export const FETCH_EMPLOYEES_BEGIN = 'FETCH_EMPLOYEES_BEGIN'
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS'
export const FETCH_EMPLOYEES_FAILURE = 'FETCH_EMPLOYEES_FAILURE'

export const fetchEmployeeBegin = () => ({
    type: FETCH_EMPLOYEES_BEGIN
})

export const fetchEmployeeSuccess = data => ({
    type: FETCH_EMPLOYEES_SUCCESS,
    payload: { data }
})

export const fetchEmployeeFailed = error => ({
    type: FETCH_EMPLOYEES_FAILURE,
    payload: { error }
})

export const FETCH_EMPLOYEESBYID_BEGIN = 'FETCH_EMPLOYEESBYID_BEGIN'
export const FETCH_EMPLOYEESBYID_SUCCESS = 'FETCH_EMPLOYEESBYID_SUCCESS'
export const FETCH_EMPLOYEESBYID_FAILURE = 'FETCH_EMPLOYEESBYID_FAILURE'

export const fetchEmployeeByIdBegin = () => ({
    type: FETCH_EMPLOYEESBYID_BEGIN
})

export const fetchEmployeeByIdSuccess = data => ({
    type: FETCH_EMPLOYEESBYID_SUCCESS,
    payload: { data }
})

export const fetchEmployeeByIdFailed = error => ({
    type: FETCH_EMPLOYEESBYID_FAILURE,
    payload: { error }
})


//Bank
export const FETCH_BANK_BEGIN = 'FETCH_BANK_BEGIN'
export const FETCH_BANK_SUCCESS = 'FETCH_BANK_SUCCESS'
export const FETCH_BANK_FAILURE = 'FETCH_BANK_FAILURE'

export const fetchBankBegin = () => ({
    type: FETCH_BANK_BEGIN
})

export const fetchBankSuccess = data => ({
    type: FETCH_BANK_SUCCESS,
    payload: { data }
})

export const fetchBankFailed = error => ({
    type: FETCH_BANK_FAILURE,
    payload: { error }
})

//Holiday
export const FETCH_HOLIDAY_BEGIN = 'FETCH_HOLIDAY_BEGIN'
export const FETCH_HOLIDAY_SUCCESS = 'FETCH_HOLIDAY_SUCCESS'
export const FETCH_HOLIDAY_FAILURE = 'FETCH_HOLIDAY_FAILURE'

export const fetchHolidayBegin = () => ({
    type: FETCH_HOLIDAY_BEGIN
})

export const fetchHolidaySuccess = data => ({
    type: FETCH_HOLIDAY_SUCCESS,
    payload: { data }
})

export const fetchHolidayFailed = error => ({
    type: FETCH_HOLIDAY_FAILURE,
    payload: { error }
})

//EmployeeBankDetails

export const FETCH_EMPLOYEESBANKDETAILS_BEGIN = 'FETCH_EMPLOYEESBANKDETAILS_BEGIN'
export const FETCH_EMPLOYEESBANKDETAILS_SUCCESS = 'FETCH_EMPLOYEESBANKDETAILS_SUCCESS'
export const FETCH_EMPLOYEESBANKDETAILS_FAILURE = 'FETCH_EMPLOYEESBANKDETAILS_FAILURE'

export const fetchEmpBankDetailsBegin = () => ({
    type: FETCH_EMPLOYEESBANKDETAILS_BEGIN
})

export const fetchEmpBankDetailsSuccess = data => ({
    type: FETCH_EMPLOYEESBANKDETAILS_SUCCESS,
    payload: { data }
})

export const fetchEmpBankDetailsFailed = error => ({
    type: FETCH_EMPLOYEESBANKDETAILS_FAILURE,
    payload: { error }
})



// //Branch

export const FETCH_BRANCH_BEGIN = 'FETCH_BRANCHES_BEGIN'
export const FETCH_BRANCH_SUCCESS = 'FETCH_BRANCHES_SUCCESS'
export const FETCH_BRANCH_FAILURE = 'FETCH_BRANCHES_FAILURE'

export const fetchBranchBegin = () => ({
    type: FETCH_BRANCH_BEGIN
})

export const fetchBranchSuccess = data => ({
    type: FETCH_BRANCH_SUCCESS,
    payload: { data }
})

export const fetchBranchFailed = error => ({
    type: FETCH_BRANCH_FAILURE,
    payload: { error }
})

//Designation

export const FETCH_DESIGNATION_BEGIN = 'FETCH_DESIGNATION_BEGIN'
export const FETCH_DESIGNATION_SUCCESS = 'FETCH_DESIGNATION_SUCCESS'
export const FETCH_DESIGNATION_FAILURE = 'FETCH_DESIGNATION_FAILURE'

export const fetchDesignationBegin = () => ({
    type: FETCH_DESIGNATION_BEGIN
})

export const fetchDesignationSuccess = data => ({
    type: FETCH_DESIGNATION_SUCCESS,
    payload: { data }
})

export const fetchDesignationFailed = error => ({
    type: FETCH_DESIGNATION_FAILURE,
    payload: { error }
})

//Department

export const FETCH_DEPARTMENT_BEGIN = 'FETCH_DEPARTMENT_BEGIN'
export const FETCH_DEPARTMENT_SUCCESS = 'FETCH_DEPARTMENT_SUCCESS'
export const FETCH_DEPARTMENT_FAILURE = 'FETCH_DEPARTMENT_FAILURE'

export const fetchDepartmentBegin = () => ({
    type: FETCH_DEPARTMENT_BEGIN
})

export const fetchDepartmentSuccess = data => ({
    type: FETCH_DEPARTMENT_SUCCESS,
    payload: { data }
})

export const fetchDepartmentFailed = error => ({
    type: FETCH_DEPARTMENT_FAILURE,
    payload: { error }
})

//Tasks

export const FETCH_TASK_BEGIN = 'FETCH_TASK_BEGIN'
export const FETCH_TASK_SUCCESS = 'FETCH_TASK_SUCCESS'
export const FETCH_TASK_FAILURE = 'FETCH_TASK_FAILURE'

export const fetchTaskBegin = () => ({
    type: FETCH_TASK_BEGIN
})

export const fetchTaskSuccess = data => ({
    type: FETCH_TASK_SUCCESS,
    payload: { data }
})

export const fetchTaskFailed = error => ({
    type: FETCH_TASK_FAILURE,
    payload: { error }
})

//Tasks Follow Up

export const FETCH_FOLLOWUP_BEGIN = 'FETCH_FOLLOWUP_BEGIN'
export const FETCH_FOLLOWUP_SUCCESS = 'FETCH_FOLLOWUP_SUCCESS'
export const FETCH_FOLLOWUP_FAILURE = 'FETCH_FOLLOWUP_FAILURE'

export const fetchFollowUpBegin = () => ({
    type: FETCH_FOLLOWUP_BEGIN
})

export const fetchFollowUpSuccess = data => ({
    type: FETCH_FOLLOWUP_SUCCESS,
    payload: { data }
})

export const fetchFollowUpFailed = error => ({
    type: FETCH_FOLLOWUP_FAILURE,
    payload: { error }
})


//Tasks History

export const FETCH_TASKHISTORY_BEGIN = 'FETCH_TASKHISTORY_BEGIN'
export const FETCH_TASKHISTORY_SUCCESS = 'FETCH_TASKHISTORY_SUCCESS'
export const FETCH_TASKHISTORY_FAILURE = 'FETCH_TASKHISTORY_FAILURE'

export const fetchTaskHistoryBegin = () => ({
    type: FETCH_TASKHISTORY_BEGIN
})

export const fetchTaskHistorySuccess = data => ({
    type: FETCH_TASKHISTORY_SUCCESS,
    payload: { data }
})

export const fetchTaskHistoryFailed = error => ({
    type: FETCH_TASKHISTORY_FAILURE,
    payload: { error }
})


//reports
export const REPORT_BEGIN = 'REPORT_BEGIN'
export const REPORT_SUCCESS = 'REPORT_SUCCESS'
export const REPORT_FAILURE = 'REPORT_FAILURE'

export const reportBegin = () => ({
    type: REPORT_BEGIN
})

export const reportSuccess = data => ({
    type: REPORT_SUCCESS,
    payload: { data }
})

export const reportFailure = error => ({
    type: REPORT_FAILURE,
    payload: { error }
})

//State
export const STATE_BEGIN = 'STATE_BEGIN'
export const STATE_SUCCESS = 'STATE_SUCCESS'
export const STATE_FAILURE = 'STATE_FAILURE'

export const fetchStateBegin = () => ({
    type: STATE_BEGIN
})

export const fetchStateSuccess = data => ({
    type: STATE_SUCCESS,
    payload: { data }
})

export const fetchStateFailed = error => ({
    type: STATE_FAILURE,
    payload: { error }
})

//City
export const CITY_BEGIN = 'CITY_BEGIN'
export const CITY_SUCCESS = 'CITY_SUCCESS'
export const CITY_FAILURE = 'CITY_FAILURE'

export const fetchCityBegin = () => ({
    type: CITY_BEGIN
})

export const fetchCitySuccess = data => ({
    type: CITY_SUCCESS,
    payload: { data }
})

export const fetchCityFailed = error => ({
    type: CITY_FAILURE,
    payload: { error }
})




//exchanges
export const FETCH_EXCHANGE_BEGIN = 'FETCH_EXCHANGE_BEGIN'
export const FETCH_EXCHANGE_SUCCESS = 'FETCH_EXCHANGE_SUCCESS'
export const FETCH_EXCHANGE_FAILURE = 'FETCH_EXCHANGE_FAILURE'

export const fetchExchangeBegin = () => ({
    type: FETCH_EXCHANGE_BEGIN
})

export const fetchExchangeSuccess = exchanges => ({
    type: FETCH_EXCHANGE_SUCCESS,
    payload: { exchanges }
})

export const fetchExchangeFailed = error => ({
    type: FETCH_EXCHANGE_FAILURE,
    payload: { error }
})


//security holdings reports
export const HOLDINGS_REPORT_BEGIN = 'HOLDINGS_REPORT_BEGIN'
export const HOLDINGS_REPORT_SUCCESS = 'HOLDINGS_REPORT_SUCCESS'
export const HOLDINGS_REPORT_FAILURE = 'HOLDINGS_REPORT_FAILURE'

export const holdingsReportBegin = () => ({
    type: HOLDINGS_REPORT_BEGIN
})

export const holdingsReportSuccess = data => ({
    type: HOLDINGS_REPORT_SUCCESS,
    payload: { data }
})

export const holdingsReportFailure = error => ({
    type: HOLDINGS_REPORT_FAILURE,
    payload: { error }
})

//position reports
export const POSITION_REPORT_BEGIN = 'POSITION_REPORT_BEGIN'
export const POSITION_REPORT_SUCCESS = 'POSITION_REPORT_SUCCESS'
export const POSITION_REPORT_FAILURE = 'POSITION_REPORT_FAILURE'

export const positionReportBegin = () => ({
    type: POSITION_REPORT_BEGIN
})

export const positionReportSuccess = data => ({
    type: POSITION_REPORT_SUCCESS,
    payload: { data }
})

export const positionReportFailure = error => ({
    type: POSITION_REPORT_FAILURE,
    payload: { error }
})

//tradedates reports
export const FETCH_TRADEDATE_BEGIN = 'FETCH_TRADEDATE_BEGIN'
export const FETCH_TRADEDATE_SUCCESS = 'FETCH_TRADEDATE_SUCCESS'
export const FETCH_TRADEDATE_FAILURE = 'FETCH_TRADEDATE_FAILURE'

export const fetchTradedateBegin = () => ({
    type: FETCH_TRADEDATE_BEGIN
})

export const fetchTradedateSuccess = tradedates => ({
    type: FETCH_TRADEDATE_SUCCESS,
    payload: { tradedates }
})

export const fetchTradedateFailure = error => ({
    type: FETCH_TRADEDATE_FAILURE,
    payload: { error }
})

export const FETCH_TRADES_BEGIN = 'FETCH_TRADES_BEGIN'
export const FETCH_TRADES_SUCCESS = 'FETCH_TRADES_SUCCESS'
export const FETCH_TRADES_FAILURE = 'FETCH_TRADES_FAILURE'

export const fetchTradesBegin = () => ({
    type: FETCH_TRADES_BEGIN
})

export const fetchTradesSuccess = trades => ({
    type: FETCH_TRADES_SUCCESS,
    payload: { trades }
})

export const fetchTradesFailure = error => ({
    type: FETCH_TRADES_FAILURE,
    payload: { error }
})


export const FETCH_DOWNLOADS_BEGIN = 'FETCH_DOWNLOADS_BEGIN'
export const FETCH_DOWNLOADS_SUCCESS = 'FETCH_DOWNLOADS_SUCCESS'
export const FETCH_DOWNLOADS_FAILURE = 'FETCH_DOWNLOADS_FAILURE'

export const fetchDownloadsBegin = () => ({
    type: FETCH_DOWNLOADS_BEGIN
})

export const fetchDownloadsSuccess = downloads => ({
    type: FETCH_DOWNLOADS_SUCCESS,
    payload: { downloads }
})

export const fetchDownloadsFailure = error => ({
    type: FETCH_DOWNLOADS_FAILURE,
    payload: { error }
})

export const FETCH_CASHBILL_BEGIN = 'FETCH_CASHBILL_BEGIN'
export const FETCH_CASHBILL_SUCCESS = 'FETCH_CASHBILL_SUCCESS'
export const FETCH_CASHBILL_FAILURE = 'FETCH_CASHBILL_FAILURE'

export const fetchCashbillBegin = () => ({
    type: FETCH_CASHBILL_BEGIN
})

export const fetchCashbillSuccess = cashbills => ({
    type: FETCH_CASHBILL_SUCCESS,
    payload: { cashbills }
})

export const fetchCashbillFailure = error => ({
    type: FETCH_CASHBILL_FAILURE,
    payload: { error }
})

export const FETCH_TAXPNLEQ_BEGIN = 'FETCH_TAXPNLEQ_BEGIN'
export const FETCH_TAXPNLEQ_SUCCESS = 'FETCH_TAXPNLEQ_SUCCESS'
export const FETCH_TAXPNLEQ_FAILURE = 'FETCH_TAXPNLEQ_FAILURE'

export const fetchTaxPNLEqBegin = () => ({
    type: FETCH_TAXPNLEQ_BEGIN
})

export const fetchTaxPNLEqSuccess = records => ({
    type: FETCH_TAXPNLEQ_SUCCESS,
    payload: { records }
})

export const fetchTaxPNLEqFailure = error => ({
    type: FETCH_TAXPNLEQ_FAILURE,
    payload: { error }
})

//pnldates reports
export const FETCH_PNLDATE_BEGIN = 'FETCH_PNLDATE_BEGIN'
export const FETCH_PNLDATE_SUCCESS = 'FETCH_PNLDATE_SUCCESS'
export const FETCH_PNLDATE_FAILURE = 'FETCH_PNLDATE_FAILURE'

export const fetchPNLdateBegin = () => ({
    type: FETCH_PNLDATE_BEGIN
})

export const fetchPNLdateSuccess = pnldates => ({
    type: FETCH_PNLDATE_SUCCESS,
    payload: { pnldates }
})

export const fetchPNLdateFailure = error => ({
    type: FETCH_PNLDATE_FAILURE,
    payload: { error }
})

//pnltrades reports
export const FETCH_PNLTRADES_BEGIN = 'FETCH_PNLTRADES_BEGIN'
export const FETCH_PNLTRADES_SUCCESS = 'FETCH_PNLTRADES_SUCCESS'
export const FETCH_PNLTRADES_FAILURE = 'FETCH_PNLTRADES_FAILURE'

export const fetchPNLTradesBegin = () => ({
    type: FETCH_PNLTRADES_BEGIN
})

export const fetchPNLTradesSuccess = pnltrades => ({
    type: FETCH_PNLTRADES_SUCCESS,
    payload: { pnltrades }
})

export const fetchPNLTradesFailure = error => ({
    type: FETCH_PNLTRADES_FAILURE,
    payload: { error }
})

//pnl expenses reports
export const FETCH_PNLEXPENSES_BEGIN = 'FETCH_PNLEXPENSES_BEGIN'
export const FETCH_PNLEXPENSES_SUCCESS = 'FETCH_PNLEXPENSES_SUCCESS'
export const FETCH_PNLEXPENSES_FAILURE = 'FETCH_PNLEXPENSES_FAILURE'

export const fetchPNLExpensesBegin = () => ({
    type: FETCH_PNLEXPENSES_BEGIN
})

export const fetchPNLExpensesSuccess = expenses => ({
    type: FETCH_PNLEXPENSES_SUCCESS,
    payload: { expenses }
})

export const fetchPNLExpensesFailure = error => ({
    type: FETCH_PNLEXPENSES_FAILURE,
    payload: { error }
})

//tax pnl derivatives reports
export const FETCH_PNLDERIVATIVES_BEGIN = 'FETCH_PNLDERIVATIVES_BEGIN'
export const FETCH_PNLDERIVATIVES_SUCCESS = 'FETCH_PNLDERIVATIVES_SUCCESS'
export const FETCH_PNLDERIVATIVES_FAILURE = 'FETCH_PNLDERIVATIVES_FAILURE'

export const fetchPNLDerivativesBegin = () => ({
    type: FETCH_PNLDERIVATIVES_BEGIN
})

export const fetchPNLDerivativesSuccess = records => ({
    type: FETCH_PNLDERIVATIVES_SUCCESS,
    payload: { records }
})

export const fetchPNLDerivativesFailure = error => ({
    type: FETCH_PNLDERIVATIVES_FAILURE,
    payload: { error }
})

//tax pnl derivatives reports
export const FETCH_PNLCURRENCIES_BEGIN = 'FETCH_PNLCURRENCIES_BEGIN'
export const FETCH_PNLCURRENCIES_SUCCESS = 'FETCH_PNLCURRENCIES_SUCCESS'
export const FETCH_PNLCURRENCIES_FAILURE = 'FETCH_PNLCURRENCIES_FAILURE'

export const fetchPNLCurrenciesBegin = () => ({
    type: FETCH_PNLCURRENCIES_BEGIN
})

export const fetchPNLCurrenciesSuccess = records => ({
    type: FETCH_PNLCURRENCIES_SUCCESS,
    payload: { records }
})

export const fetchPNLCurrenciesFailure = error => ({
    type: FETCH_PNLCURRENCIES_FAILURE,
    payload: { error }
})

//user bank accounts
export const FETCH_BANKACCOUNTS_BEGIN = 'FETCH_BANKACCOUNTS_BEGIN'
export const FETCH_BANKACCOUNTS_SUCCESS = 'FETCH_BANKACCOUNTS_SUCCESS'
export const FETCH_BANKACCOUNTS_FAILURE = 'FETCH_BANKACCOUNTS_FAILURE'

export const fetchBanksBegin = () => ({
    type: FETCH_BANKACCOUNTS_BEGIN
})

export const fetchBanksSuccess = banks => ({
    type: FETCH_BANKACCOUNTS_SUCCESS,
    payload: { banks }
})

export const fetchBanksFailure = error => ({
    type: FETCH_BANKACCOUNTS_FAILURE,
    payload: { error }
})

//add funds
export const ADD_FUNDS_BEGIN = 'ADD_FUNDS_BEGIN'
export const ADD_FUNDS_SUCCESS = 'ADD_FUNDS_SUCCESS'
export const ADD_FUNDS_FAILURE = 'ADD_FUNDS_FAILURE'

export const addFundsBegin = () => ({
    type: ADD_FUNDS_BEGIN
})

export const addFundsSuccess = fund => ({
    type: ADD_FUNDS_SUCCESS,
    payload: { fund }
})

export const addFundsFailure = error => ({
    type: ADD_FUNDS_FAILURE,
    payload: { error }
})

//fetch funds
export const FETCH_FUNDS_BEGIN = 'FETCH_FUNDS_BEGIN'
export const FETCH_FUNDS_SUCCESS = 'FETCH_FUNDS_SUCCESS'
export const FETCH_FUNDS_FAILURE = 'FETCH_FUNDS_FAILURE'

export const fetchFundsBegin = () => ({
    type: FETCH_FUNDS_BEGIN
})

export const fetchFundsSuccess = funds => ({
    type: FETCH_FUNDS_SUCCESS,
    payload: { funds }
})

export const fetchFundsFailure = error => ({
    type: FETCH_FUNDS_FAILURE,
    payload: { error }
})

//fetch dashboard
export const FETCH_DASHBOARD_BEGIN = 'FETCH_DASHBOARD_BEGIN'
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS'
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE'

export const fetchDashboardBegin = () => ({
    type: FETCH_DASHBOARD_BEGIN
})

export const fetchDashbaordSuccess = dashboard => ({
    type: FETCH_DASHBOARD_SUCCESS,
    payload: { dashboard }
})

export const fetchDashboardFailure = error => ({
    type: FETCH_DASHBOARD_FAILURE,
    payload: { error }
})

//fetch dues
export const FETCH_DUES_BEGIN = 'FETCH_DUES_BEGIN'
export const FETCH_DUES_SUCCESS = 'FETCH_DUES_SUCCESS'
export const FETCH_DUES_FAILURE = 'FETCH_DUES_FAILURE'

export const fetchDuesBegin = () => ({
    type: FETCH_DUES_BEGIN
})

export const fetchDuesSuccess = dues => ({
    type: FETCH_DUES_SUCCESS,
    payload: { dues }
})

export const fetchDuesFailure = error => ({
    type: FETCH_DUES_FAILURE,
    payload: { error }
})

//add withdraw request
export const ADD_WITHDRAW_BEGIN = 'ADD_WITHDRAW_BEGIN'
export const ADD_WITHDRAW_SUCCESS = 'ADD_WITHDRAW_SUCCESS'
export const ADD_WITHDRAW_FAILURE = 'ADD_WITHDRAW_FAILURE'

export const addWithdrawBegin = () => ({
    type: ADD_WITHDRAW_BEGIN
})

export const addWithdrawSuccess = withdraw => ({
    type: ADD_WITHDRAW_SUCCESS,
    payload: { withdraw }
})

export const addWithdrawFailure = error => ({
    type: ADD_WITHDRAW_FAILURE,
    payload: { error }
})

//fetch withdraw request
export const FETCH_WITHDRAW_BEGIN = 'FETCH_WITHDRAW_BEGIN'
export const FETCH_WITHDRAW_SUCCESS = 'FETCH_WITHDRAW_SUCCESS'
export const FETCH_WITHDRAW_FAILURE = 'FETCH_WITHDRAW_FAILURE'

export const fetchWithdrawBegin = () => ({
    type: FETCH_WITHDRAW_BEGIN
})

export const fetchWithdrawSuccess = withdraw => ({
    type: FETCH_WITHDRAW_SUCCESS,
    payload: { withdraw }
})

export const fetchWithdrawFailure = error => ({
    type: FETCH_WITHDRAW_FAILURE,
    payload: { error }
})

//fetch past ipo
export const FETCH_PASTIPO_BEGIN = 'FETCH_PASTIPO_BEGIN'
export const FETCH_PASTIPO_SUCCESS = 'FETCH_PASTIPO_SUCCESS'
export const FETCH_PASTIPO_FAILURE = 'FETCH_PASTIPO_FAILURE'

export const fetchPastIPOBegin = () => ({
    type: FETCH_PASTIPO_BEGIN
})

export const fetchPastIPOSuccess = ipos => ({
    type: FETCH_PASTIPO_SUCCESS,
    payload: { ipos }
})

export const fetchPastIPOFailure = error => ({
    type: FETCH_PASTIPO_FAILURE,
    payload: { error }
})

//fetch future ipo
export const FETCH_FUTUREIPO_BEGIN = 'FETCH_FUTUREIPO_BEGIN'
export const FETCH_FUTUREIPO_SUCCESS = 'FETCH_FUTUREIPO_SUCCESS'
export const FETCH_FUTUREIPO_FAILURE = 'FETCH_FUTUREIPO_FAILURE'

export const fetchFutureIPOBegin = () => ({
    type: FETCH_FUTUREIPO_BEGIN
})

export const fetchFutureIPOSuccess = ipos => ({
    type: FETCH_FUTUREIPO_SUCCESS,
    payload: { ipos }
})

export const fetchFutureIPOFailure = error => ({
    type: FETCH_FUTUREIPO_FAILURE,
    payload: { error }
})

//fetch current ipo
export const FETCH_CURRENTIPO_BEGIN = 'FETCH_CURRENTIPO_BEGIN'
export const FETCH_CURRENTIPO_SUCCESS = 'FETCH_CURRENTIPO_SUCCESS'
export const FETCH_CURRENTIPO_FAILURE = 'FETCH_CURRENTIPO_FAILURE'

export const fetchCurrentIPOBegin = () => ({
    type: FETCH_CURRENTIPO_BEGIN
})

export const fetchCurrentIPOSuccess = ipos => ({
    type: FETCH_CURRENTIPO_SUCCESS,
    payload: { ipos }
})

export const fetchCurrentIPOFailure = error => ({
    type: FETCH_CURRENTIPO_FAILURE,
    payload: { error }
})

//add ipo order
export const ADD_IPOORDER_BEGIN = 'ADD_IPOORDER_BEGIN'
export const ADD_IPOORDER_SUCCESS = 'ADD_IPOORDER_SUCCESS'
export const ADD_IPOORDER_FAILURE = 'ADD_IPOORDER_FAILURE'

export const addIPOOrderBegin = () => ({
    type: ADD_IPOORDER_BEGIN
})

export const addIPOOrderSuccess = ipo => ({
    type: ADD_IPOORDER_SUCCESS,
    payload: { ipo }
})

export const addIPOOrderFailure = error => ({
    type: ADD_IPOORDER_FAILURE,
    payload: { error }
})



//IPADDRESS

export const FETCH_IPADDRESS_SAVE = 'FETCH_IPADDRESS_SAVE'

export const fetchIPAddressSave = (data) => ({
    type: FETCH_IPADDRESS_SAVE,
    payload: { data }
})


//AccountOffers

export const FETCH_ACCOUNTOFFERS_BEGIN = 'FETCH_ACCOUNTOFFERS_BEGIN'
export const FETCH_ACCOUNTOFFERS_SUCCESS = 'FETCH_ACCOUNTOFFERS_SUCCESS'
export const FETCH_ACCOUNTOFFERS_FAILURE = 'FETCH_ACCOUNTOFFERS_FAILURE'

export const fetchAccountOffersBegin = () => ({
    type: FETCH_ACCOUNTOFFERS_BEGIN
})

export const fetchAccountOffersSuccess = data => ({
    type: FETCH_ACCOUNTOFFERS_SUCCESS,
    payload: { data }
})

export const fetchAccountOffersFailed = error => ({
    type: FETCH_ACCOUNTOFFERS_FAILURE,
    payload: { error }
})

//UseFull Links

export const FETCH_USEFULLINK_BEGIN = 'FETCH_USEFULLINK_BEGIN'
export const FETCH_USEFULLINK_SUCCESS = 'FETCH_USEFULLINK_SUCCESS'
export const FETCH_USEFULLINK_FAILURE = 'FETCH_USEFULLINK_FAILURE'

export const fetchUsefulLinkBegin = () => ({
    type: FETCH_USEFULLINK_BEGIN
})

export const fetchUsefulLinkSuccess = data => ({
    type: FETCH_USEFULLINK_SUCCESS,
    payload: { data }
})

export const fetchUsefulLinkFailed = error => ({
    type: FETCH_USEFULLINK_FAILURE,
    payload: { error }
})

//Designation By Department

export const FETCH_DESIGNATION_BY_DEPARTMENT_ID_BEGIN = 'FETCH_DESIGNATION_BY_DEPARTMENT_ID_BEGIN'
export const FETCH_DESIGNATION_BY_DEPARTMENT_ID_SUCCESS = 'FETCH_DESIGNATION_BY_DEPARTMENT_ID_SUCCESS'
export const FETCH_DESIGNATION_BY_DEPARTMENT_ID_FAILURE = 'FETCH_DESIGNATION_BY_DEPARTMENT_ID_FAILURE'

export const fetchDesignationByDepartmentIdBegin = () => ({
    type: FETCH_DESIGNATION_BY_DEPARTMENT_ID_BEGIN
})

export const fetchDesignationByDepartmentIdSuccess = data => ({
    type: FETCH_DESIGNATION_BY_DEPARTMENT_ID_SUCCESS,
    payload: { data }
})

export const fetchDesignationByDepartmentIdFailed = error => ({
    type: FETCH_DESIGNATION_BY_DEPARTMENT_ID_FAILURE,
    payload: { error }
})


//Client Code

export const FETCH_CLIENT_CODE_BEGIN = 'FETCH_CLIENT_CODE_BEGIN'
export const FETCH_CLIENT_CODE_SUCCESS = 'FETCH_CLIENT_CODE_SUCCESS'
export const FETCH_CLIENT_CODE_FAILURE = 'FETCH_CLIENT_CODE_FAILURE'

export const fetchClientCodeBegin = () => ({
    type: FETCH_CLIENT_CODE_BEGIN
})

export const fetchClientCodeSuccess = data => ({
    type: FETCH_CLIENT_CODE_SUCCESS,
    payload: { data }
})

export const fetchClientCodeFailed = error => ({
    type: FETCH_CLIENT_CODE_FAILURE,
    payload: { error }
})

//Email Code

export const FETCH_EMAIL_BEGIN = 'FETCH_EMAIL_BEGIN'
export const FETCH_EMAIL_SUCCESS = 'FETCH_EMAIL_SUCCESS'
export const FETCH_EMAIL_FAILURE = 'FETCH_EMAIL_FAILURE'

export const fetchEmailBegin = () => ({
    type: FETCH_EMAIL_BEGIN
})

export const fetchEmailSuccess = data => ({
    type: FETCH_EMAIL_SUCCESS,
    payload: { data }
})

export const fetchEmailFailed = error => ({
    type: FETCH_EMAIL_FAILURE,
    payload: { error }
})

//Folder Code

export const FETCH_FOLDER_BEGIN = 'FETCH_FOLDER_BEGIN'
export const FETCH_FOLDER_SUCCESS = 'FETCH_FOLDER_SUCCESS'
export const FETCH_FOLDER_FAILURE = 'FETCH_FOLDER_FAILURE'

export const fetchFolderBegin = () => ({
    type: FETCH_FOLDER_BEGIN
})

export const fetchFolderSuccess = data => ({
    type: FETCH_FOLDER_SUCCESS,
    payload: { data }
})

export const fetchFolderFailed = error => ({
    type: FETCH_FOLDER_FAILURE,
    payload: { error }
})

export const FETCH_BLOCKLIST_BEGIN = 'FETCH_BLOCKLIST_BEGIN'
export const FETCH_BLOCKLIST_SUCCESS = 'FETCH_BLOCKLIST_SUCCESS'
export const FETCH_BLOCKLIST_FAILURE = 'FETCH_BLOCKLIST_FAILURE'

export const fetchBlocklistBegin = () => ({
    type: FETCH_BLOCKLIST_BEGIN
})

export const fetchBlocklistSuccess = data => ({
    type: FETCH_BLOCKLIST_SUCCESS,
    payload: { data }
})

export const fetchBlocklistFailed = error => ({
    type: FETCH_BLOCKLIST_FAILURE,
    payload: { error }
})

//SOP

export const FETCH_SOP_BEGIN = 'FETCH_SOP_BEGIN'
export const FETCH_SOP_SUCCESS = 'FETCH_SOP_SUCCESS'
export const FETCH_SOP_FAILURE = 'FETCH_SOP_FAILURE'

export const fetchSOPBegin = () => ({
    type: FETCH_SOP_BEGIN
})

export const fetchSOPSuccess = data => ({
    type: FETCH_SOP_SUCCESS,
    payload: { data }
})

export const fetchSOPFailed = error => ({
    type: FETCH_SOP_FAILURE,
    payload: { error }
})

//SOP CATEGORY

export const FETCH_SOP_CATEGORY_BEGIN = 'FETCH_SOP_CATEGORY_BEGIN'
export const FETCH_SOP_CATEGORY_SUCCESS = 'FETCH_SOP_CATEGORY_SUCCESS'
export const FETCH_SOP_CATEGORY_FAILURE = 'FETCH_SOP_CATEGORY_FAILURE'

export const fetchSOPCategoryBegin = () => ({
    type: FETCH_SOP_CATEGORY_BEGIN
})

export const fetchSOPCategorySuccess = data => ({
    type: FETCH_SOP_CATEGORY_SUCCESS,
    payload: { data }
})

export const fetchSOPCategoryFailed = error => ({
    type: FETCH_SOP_CATEGORY_FAILURE,
    payload: { error }
})

//SOP RECENT POST

export const FETCH_SOP_RECENT_POST_BEGIN = 'FETCH_SOP_RECENT_POST_BEGIN'
export const FETCH_SOP_RECENT_POST_SUCCESS = 'FETCH_SOP_RECENT_POST_SUCCESS'
export const FETCH_SOP_RECENT_POST_FAILURE = 'FETCH_SOP_RECENT_POST_FAILURE'

export const fetchSOPRecentPostBegin = () => ({
    type: FETCH_SOP_RECENT_POST_BEGIN
})

export const fetchSOPRecentPostSuccess = data => ({
    type: FETCH_SOP_RECENT_POST_SUCCESS,
    payload: { data }
})

export const fetchSOPRecentPostFailed = error => ({
    type: FETCH_SOP_RECENT_POST_FAILURE,
    payload: { error }
})

//CONTACT

export const FETCH_CONTACT_BEGIN = 'FETCH_CONTACT_BEGIN'
export const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS'
export const FETCH_CONTACT_FAILURE = 'FETCH_CONTACT_FAILURE'

export const fetchContactBegin = () => ({
    type: FETCH_CONTACT_BEGIN
})

export const fetchContactSuccess = data => ({
    type: FETCH_CONTACT_SUCCESS,
    payload: { data }
})

export const fetchContactFailed = error => ({
    type: FETCH_CONTACT_FAILURE,
    payload: { error }
})


//REVERSE CLIENT

export const FETCH_REVERSE_CLIENT_BEGIN = 'FETCH_REVERSE_CLIENT_BEGIN'
export const FETCH_REVERSE_CLIENT_SUCCESS = 'FETCH_REVERSE_CLIENT_SUCCESS'
export const FETCH_REVERSE_CLIENT_FAILURE = 'FETCH_REVERSE_CLIENT_FAILURE'

export const fetchReverseClientBegin = () => ({
    type: FETCH_REVERSE_CLIENT_BEGIN
})

export const fetchReverseClientSuccess = data => ({
    type: FETCH_REVERSE_CLIENT_SUCCESS,
    payload: { data }
})

export const fetchReverseClientFailed = error => ({
    type: FETCH_REVERSE_CLIENT_FAILURE,
    payload: { error }
})

//RMSTTransfer

export const FETCH_RMS_T_TRANSFER_BEGIN = 'FETCH_RMS_T_TRANSFER_BEGIN'
export const FETCH_RMS_T_TRANSFER_SUCCESS = 'FETCH_RMS_T_TRANSFER_SUCCESS'
export const FETCH_RMS_T_TRANSFER_FAILURE = 'FETCH_RMS_T_TRANSFER_FAILURE'

export const fetchRMSTTransferBegin = () => ({
    type: FETCH_RMS_T_TRANSFER_BEGIN
})

export const fetchRMSTTransferSuccess = data => ({
    type: FETCH_RMS_T_TRANSFER_SUCCESS,
    payload: { data }
})

export const fetchRMSTTransferFailed = error => ({
    type: FETCH_RMS_T_TRANSFER_FAILURE,
    payload: { error }
})

//RMSTTransfer

export const FETCH_RMS_T_TRANSFER_DOWNLOADFILE_BEGIN = 'FETCH_RMS_T_TRANSFER_DOWNLOADFILE_BEGIN'
export const FETCH_RMS_T_TRANSFER_DOWNLOADFILE_SUCCESS = 'FETCH_RMS_T_TRANSFER_DOWNLOADFILE_SUCCESS'
export const FETCH_RMS_T_TRANSFER_DOWNLOADFILE_FAILURE = 'FETCH_RMS_T_TRANSFER_DOWNLOADFILE_FAILURE'

export const fetchRMSTTransferDownloadFileBegin = () => ({
    type: FETCH_RMS_T_TRANSFER_DOWNLOADFILE_BEGIN
})

export const fetchRMSTTransferDownloadFileSuccess = data => ({
    type: FETCH_RMS_T_TRANSFER_DOWNLOADFILE_SUCCESS,
    payload: { data }
})

export const fetchRMSTTransferDownloadFileFailed = error => ({
    type: FETCH_RMS_T_TRANSFER_DOWNLOADFILE_FAILURE,
    payload: { error }
})


export const FETCH_ORGANIZATION_BEGIN = 'FETCH_ORGANIZATION_BEGIN'
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS'
export const FETCH_ORGANIZATION_FAILURE = 'FETCH_ORGANIZATION_FAILURE'

export const fetchOrganizationBegin = () => ({
    type: FETCH_ORGANIZATION_BEGIN
})

export const fetchOrganizationSuccess = data => ({
    type: FETCH_ORGANIZATION_SUCCESS,
    payload: { data }
})

export const fetchOrganizationFailed = error => ({
    type: FETCH_ORGANIZATION_FAILURE,
    payload: { error }
})


//VACANCY

export const FETCH_VACANCY_BEGIN = 'FETCH_VACANCY_BEGIN'
export const FETCH_VACANCY_SUCCESS = 'FETCH_VACANCY_SUCCESS'
export const FETCH_VACANCY_FAILURE = 'FETCH_VACANCY_FAILURE'

export const fetchVacancyBegin = () => ({
    type: FETCH_VACANCY_BEGIN
})

export const fetchVacancySuccess = data => ({
    type: FETCH_VACANCY_SUCCESS,
    payload: { data }
})

export const fetchVacancyFailed = error => ({
    type: FETCH_VACANCY_FAILURE,
    payload: { error }
})

//VACANCY CAREERS (RESUME)

export const FETCH_VACANCY_CAREER_BEGIN = 'FETCH_VACANCY_CAREER_BEGIN'
export const FETCH_VACANCY_CAREER_SUCCESS = 'FETCH_VACANCY_CAREER_SUCCESS'
export const FETCH_VACANCY_CAREER_FAILURE = 'FETCH_VACANCY_CAREER_FAILURE'

export const fetchVacancyCareerBegin = () => ({
    type: FETCH_VACANCY_CAREER_BEGIN
})

export const fetchVacancyCareerSuccess = data => ({
    type: FETCH_VACANCY_CAREER_SUCCESS,
    payload: { data }
})

export const fetchVacancyCareerFailed = error => ({
    type: FETCH_VACANCY_CAREER_FAILURE,
    payload: { error }
})

//DEALER KEY

export const FETCH_DEALER_KEY_BEGIN = 'FETCH_DEALER_KEY_BEGIN'
export const FETCH_DEALER_KEY_SUCCESS = 'FETCH_DEALER_KEY_SUCCESS'
export const FETCH_DEALER_KEY_FAILURE = 'FETCH_DEALER_KEY_FAILURE'

export const fetchDealerKeyBegin = () => ({
    type: FETCH_DEALER_KEY_BEGIN
})

export const fetchDealerKeySuccess = data => ({
    type: FETCH_DEALER_KEY_SUCCESS,
    payload: { data }
})

export const fetchDealerKeyFailed = error => ({
    type: FETCH_DEALER_KEY_FAILURE,
    payload: { error }
})


//Package Product

export const FETCH_PACKAGE_PARTNER_BEGIN = 'FETCH_PACKAGE_PARTNER_BEGIN'
export const FETCH_PACKAGE_PARTNER_SUCCESS = 'FETCH_PACKAGE_PARTNER_SUCCESS'
export const FETCH_PACKAGE_PARTNER_FAILURE = 'FETCH_PACKAGE_PARTNER_FAILURE'

export const fetchPackagePartnerBegin = () => ({
    type: FETCH_PACKAGE_PARTNER_BEGIN
})

export const fetchPackagePartnerSuccess = data => ({
    type: FETCH_PACKAGE_PARTNER_SUCCESS,
    payload: { data }
})

export const fetchPackagePartnerFailed = error => ({
    type: FETCH_PACKAGE_PARTNER_FAILURE,
    payload: { error }
})

//Package Product

export const FETCH_PACKAGE_PRODUCT_BEGIN = 'FETCH_PACKAGE_PRODUCT_BEGIN'
export const FETCH_PACKAGE_PRODUCT_SUCCESS = 'FETCH_PACKAGE_PRODUCT_SUCCESS'
export const FETCH_PACKAGE_PRODUCT_FAILURE = 'FETCH_PACKAGE_PRODUCT_FAILURE'

export const fetchPackageProductBegin = () => ({
    type: FETCH_PACKAGE_PRODUCT_BEGIN
})

export const fetchPackageProductSuccess = data => ({
    type: FETCH_PACKAGE_PRODUCT_SUCCESS,
    payload: { data }
})

export const fetchPackageProductFailed = error => ({
    type: FETCH_PACKAGE_PRODUCT_FAILURE,
    payload: { error }
})

//Package Product

export const FETCH_PACKAGE_TAGS_BEGIN = 'FETCH_PACKAGE_TAGS_BEGIN'
export const FETCH_PACKAGE_TAGS_SUCCESS = 'FETCH_PACKAGE_TAGS_SUCCESS'
export const FETCH_PACKAGE_TAGS_FAILURE = 'FETCH_PACKAGE_TAGS_FAILURE'

export const fetchPackageTagsBegin = () => ({
    type: FETCH_PACKAGE_TAGS_BEGIN
})

export const fetchPackageTagsSuccess = data => ({
    type: FETCH_PACKAGE_TAGS_SUCCESS,
    payload: { data }
})

export const fetchPackageTagsFailed = error => ({
    type: FETCH_PACKAGE_TAGS_FAILURE,
    payload: { error }
})

//Package Product

export const FETCH_PACKAGE_MASTER_BEGIN = 'FETCH_PACKAGE_MASTER_BEGIN'
export const FETCH_PACKAGE_MASTER_SUCCESS = 'FETCH_PACKAGE_MASTER_SUCCESS'
export const FETCH_PACKAGE_MASTER_FAILURE = 'FETCH_PACKAGE_MASTER_FAILURE'

export const fetchPackageMasterBegin = () => ({
    type: FETCH_PACKAGE_MASTER_BEGIN
})

export const fetchPackageMasterSuccess = data => ({
    type: FETCH_PACKAGE_MASTER_SUCCESS,
    payload: { data }
})

export const fetchPackageMasterFailed = error => ({
    type: FETCH_PACKAGE_MASTER_FAILURE,
    payload: { error }
})

//Client Subscription Package Product

export const FETCH_CLIENT_SUBSCRIPTION_BEGIN = 'FETCH_CLIENT_SUBSCRIPTION_BEGIN'
export const FETCH_CLIENT_SUBSCRIPTION_SUCCESS = 'FETCH_CLIENT_SUBSCRIPTION_SUCCESS'
export const FETCH_CLIENT_SUBSCRIPTION_FAILURE = 'FETCH_CLIENT_SUBSCRIPTION_FAILURE'

export const fetchClientSubscriptionBegin = () => ({
    type: FETCH_CLIENT_SUBSCRIPTION_BEGIN
})

export const fetchClientSubscriptionSuccess = data => ({
    type: FETCH_CLIENT_SUBSCRIPTION_SUCCESS,
    payload: { data }
})

export const fetchClientSubscriptionFailed = error => ({
    type: FETCH_CLIENT_SUBSCRIPTION_FAILURE,
    payload: { error }
})

export const FETCH_CLIENT_SUBSCRIPTION_BY_ID_BEGIN = 'FETCH_CLIENT_SUBSCRIPTION_BY_ID_BEGIN'
export const FETCH_CLIENT_SUBSCRIPTION_BY_ID_SUCCESS = 'FETCH_CLIENT_SUBSCRIPTION_BY_ID_SUCCESS'
export const FETCH_CLIENT_SUBSCRIPTION_BY_ID_FAILURE = 'FETCH_CLIENT_SUBSCRIPTION_BY_ID_FAILURE'

export const fetchClientSubscriptionByIdBegin = () => ({
    type: FETCH_CLIENT_SUBSCRIPTION_BY_ID_BEGIN
})

export const fetchClientSubscriptionByIdSuccess = data => ({
    type: FETCH_CLIENT_SUBSCRIPTION_BY_ID_SUCCESS,
    payload: { data }
})

export const fetchClientSubscriptionByIdFailed = error => ({
    type: FETCH_CLIENT_SUBSCRIPTION_BY_ID_FAILURE,
    payload: { error }
})

//Plan Master

export const FETCH_PLAN_MASTER_BEGIN = 'FETCH_PLAN_MASTER_BEGIN'
export const FETCH_PLAN_MASTER_SUCCESS = 'FETCH_PLAN_MASTER_SUCCESS'
export const FETCH_PLAN_MASTER_FAILURE = 'FETCH_PLAN_MASTER_FAILURE'

export const fetchPlanMasterBegin = () => ({
    type: FETCH_PLAN_MASTER_BEGIN
})

export const fetchPlanMasterSuccess = data => ({
    type: FETCH_PLAN_MASTER_SUCCESS,
    payload: { data }
})

export const fetchPlanMasterFailed = error => ({
    type: FETCH_PLAN_MASTER_FAILURE,
    payload: { error }
})

//VALIDITY Master

export const FETCH_VALIDITY_MASTER_BEGIN = 'FETCH_VALIDITY_MASTER_BEGIN'
export const FETCH_VALIDITY_MASTER_SUCCESS = 'FETCH_VALIDITY_MASTER_SUCCESS'
export const FETCH_VALIDITY_MASTER_FAILURE = 'FETCH_VALIDITY_MASTER_FAILURE'

export const fetchValidityMasterBegin = () => ({
    type: FETCH_VALIDITY_MASTER_BEGIN
})

export const fetchValidityMasterSuccess = data => ({
    type: FETCH_VALIDITY_MASTER_SUCCESS,
    payload: { data }
})

export const fetchValidityMasterFailed = error => ({
    type: FETCH_VALIDITY_MASTER_FAILURE,
    payload: { error }
})

//Package Price Master

export const FETCH_PACKAGE_PRICE_MASTER_BEGIN = 'FETCH_PACKAGE_PRICE_MASTER_BEGIN'
export const FETCH_PACKAGE_PRICE_MASTER_SUCCESS = 'FETCH_PACKAGE_PRICE_MASTER_SUCCESS'
export const FETCH_PACKAGE_PRICE_MASTER_FAILURE = 'FETCH_PACKAGE_PRICE_MASTER_FAILURE'

export const fetchPackagePriceMasterBegin = () => ({
    type: FETCH_PACKAGE_PRICE_MASTER_BEGIN
})

export const fetchPackagePriceMasterSuccess = data => ({
    type: FETCH_PACKAGE_PRICE_MASTER_SUCCESS,
    payload: { data }
})

export const fetchPackagePriceMasterFailed = error => ({
    type: FETCH_PACKAGE_PRICE_MASTER_FAILURE,
    payload: { error }
})

//Module Master

export const FETCH_MODULE_MASTER_BEGIN = 'FETCH_MODULE_MASTER_BEGIN'
export const FETCH_MODULE_MASTER_SUCCESS = 'FETCH_MODULE_MASTER_SUCCESS'
export const FETCH_MODULE_MASTER_FAILURE = 'FETCH_MODULE_MASTER_FAILURE'

export const fetchModuleMasterBegin = () => ({
    type: FETCH_MODULE_MASTER_BEGIN
})

export const fetchModuleMasterSuccess = data => ({
    type: FETCH_MODULE_MASTER_SUCCESS,
    payload: { data }
})

export const fetchModuleMasterFailed = error => ({
    type: FETCH_MODULE_MASTER_FAILURE,
    payload: { error }
})

//Employee Module Master

export const FETCH_EMMPLOYEE_MODULE_BEGIN = 'FETCH_EMMPLOYEE_MODULE_BEGIN'
export const FETCH_EMMPLOYEE_MODULE_SUCCESS = 'FETCH_EMMPLOYEE_MODULE_SUCCES'
export const FETCH_EMMPLOYEE_MODULE_FAILURE = 'FETCH_EMMPLOYEE_MODULE_FAILUR'

export const fetchEmployeeModuleBegin = () => ({
    type: FETCH_EMMPLOYEE_MODULE_BEGIN
})

export const fetchEmployeeModuleSuccess = data => ({
    type: FETCH_EMMPLOYEE_MODULE_SUCCESS,
    payload: { data }
})

export const fetchEmployeeModuleFailure = error => ({
    type: FETCH_EMMPLOYEE_MODULE_FAILURE,
    payload: { error }
})


//Department Module Master

export const FETCH_DEPARTMENT_MODULE_BEGIN = 'FETCH_DEPARTMENT_MODULE_BEGIN'
export const FETCH_DEPARTMENT_MODULE_SUCCESS = 'FETCH_DEPARTMENT_MODULE_SUCCES'
export const FETCH_DEPARTMENT_MODULE_FAILURE = 'FETCH_DEPARTMENT_MODULE_FAILUR'

export const fetchDepartmentModuleBegin = () => ({
    type: FETCH_DEPARTMENT_MODULE_BEGIN
})

export const fetchDepartmentModuleSuccess = data => ({
    type: FETCH_DEPARTMENT_MODULE_SUCCESS,
    payload: { data }
})

export const fetchDepartmentModuleFailure = error => ({
    type: FETCH_DEPARTMENT_MODULE_FAILURE,
    payload: { error }
})


//Form Master
export const FETCH_FORM_MASTER_BEGIN = 'FETCH_FORM_MASTER_SOP_BEGIN'
export const FETCH_FORM_MASTER_SUCCESS = 'FETCH_FORM_MASTER_SOP_SUCCESS'
export const FETCH_FORM_MASTER_FAILURE = 'FETCH_FORM_MASTER_SOP_FAILURE'

export const fetchFormMasterBegin = () => ({
    type: FETCH_FORM_MASTER_BEGIN
})

export const fetchFormMasterSuccess = data => ({
    type: FETCH_FORM_MASTER_SUCCESS,
    payload: { data }
})

export const fetchFormMasterFailed = error => ({
    type: FETCH_FORM_MASTER_FAILURE,
    payload: { error }
})

export const FETCH_FORM_CATEGORY_BEGIN = 'FETCH_FORM_CATEGORY_SOP_BEGIN'
export const FETCH_FORM_CATEGORY_SUCCESS = 'FETCH_FORM_CATEGORY_SOP_SUCCESS'
export const FETCH_FORM_CATEGORY_FAILURE = 'FETCH_FORM_CATEGORY_SOP_FAILURE'

export const fetchFormCategoryBegin = () => ({
    type: FETCH_FORM_CATEGORY_BEGIN
})

export const fetchFormCategorySuccess = data => ({
    type: FETCH_FORM_CATEGORY_SUCCESS,
    payload: { data }
})

export const fetchFormCategoryFailed = error => ({
    type: FETCH_FORM_CATEGORY_FAILURE,
    payload: { error }
})

//Client Database By UserId
export const FETCH_CLIENT_USER_BEGIN = 'FETCH_CLIENT_USER_BEGIN'
export const FETCH_CLIENT_USER_SUCCESS = 'FETCH_CLIENT_USER_SUCCESS'
export const FETCH_CLIENT_USER_FAILURE = 'FETCH_CLIENT_USER_FAILURE'

export const fetchClientUserBegin = () => ({
    type: FETCH_CLIENT_USER_BEGIN
})

export const fetchClientUserSuccess = data => ({
    type: FETCH_CLIENT_USER_SUCCESS,
    payload: { data }
})

export const fetchClientUserFailed = error => ({
    type: FETCH_CLIENT_USER_FAILURE,
    payload: { error }
})

export const FETCH_CLIENT_IMAGE_BEGIN = 'FETCH_CLIENT_IMAGE_BEGIN'
export const FETCH_CLIENT_IMAGE_SUCCESS = 'FETCH_CLIENT_IMAGE_SUCCESS'
export const FETCH_CLIENT_IMAGE_FAILURE = 'FETCH_CLIENT_IMAGE_FAILURE'

export const fetchClientImageBegin = () => ({
    type: FETCH_CLIENT_IMAGE_BEGIN
})

export const fetchClientImageSuccess = data => ({
    type: FETCH_CLIENT_IMAGE_SUCCESS,
    payload: { data }
})

export const fetchClientImageFailed = error => ({
    type: FETCH_CLIENT_IMAGE_FAILURE,
    payload: { error }
})


export const FETCH_SEGMENT_REQUEST_BEGIN = 'FETCH_SEGMENT_REQUEST_BEGIN'
export const FETCH_SEGMENT_REQUEST_SUCCESS = 'FETCH_SEGMENT_REQUEST_SUCCESS'
export const FETCH_SEGMENT_REQUEST_FAILURE = 'FETCH_SEGMENT_REQUEST_FAILURE'

export const fetchSegmentRequestBegin = () => ({
    type: FETCH_SEGMENT_REQUEST_BEGIN
})

export const fetchSegmentRequestSuccess = data => ({
    type: FETCH_SEGMENT_REQUEST_SUCCESS,
    payload: { data }
})

export const fetchSegmentRequestFailed = error => ({
    type: FETCH_SEGMENT_REQUEST_FAILURE,
    payload: { error }
})

export const FETCH_CLIENT_DATABASE_BANK_BEGIN = 'FETCH_CLIENT_DATABASE_BANK_BEGIN'
export const FETCH_CLIENT_DATABASE_BANK_SUCCESS = 'FETCH_CLIENT_DATABASE_BANK_SUCCESS'
export const FETCH_CLIENT_DATABASE_BANK_FAILURE = 'FETCH_CLIENT_DATABASE_BANK_FAILURE'

export const fetchClientDatabaseBankBegin = () => ({
    type: FETCH_CLIENT_DATABASE_BANK_BEGIN
})

export const fetchClientDatabaseBankSuccess = data => ({
    type: FETCH_CLIENT_DATABASE_BANK_SUCCESS,
    payload: { data }
})

export const fetchClientDatabaseBankFailed = error => ({
    type: FETCH_CLIENT_DATABASE_BANK_FAILURE,
    payload: { error }
})

//get Nominee
export const FETCH_CLIENT_NOMINEE_BEGIN = 'FETCH_CLIENT_NOMINEE_BEGIN'
export const FETCH_CLIENT_NOMINEE_SUCCESS = 'FETCH_CLIENT_NOMINEE_SUCCESS'
export const FETCH_CLIENT_NOMINEE_FAILURE = 'FETCH_CLIENT_NOMINEE_FAILURE'

export const fetchClientNomineeBegin = () => ({
    type: FETCH_CLIENT_NOMINEE_BEGIN
})

export const fetchClientNomineeSuccess = data => ({
    type: FETCH_CLIENT_NOMINEE_SUCCESS,
    payload: { data }
})

export const fetchClientNomineeFailed = error => ({
    type: FETCH_CLIENT_NOMINEE_FAILURE,
    payload: { error }
})

//Add Bank
export const FETCH_ADD_CLIENT_BANK_BEGIN = 'FETCH_ADD_CLIENT_BANK_BEGIN'
export const FETCH_ADD_CLIENT_BANK_SUCCESS = 'FETCH_ADD_CLIENT_BANK_SUCCESS'

export const fetchAddClientBankBegin = () => ({
    type: FETCH_ADD_CLIENT_BANK_BEGIN
})

export const fetchAddClientBankSuccess = data => ({
    type: FETCH_ADD_CLIENT_BANK_SUCCESS,
    payload: { data }
})

// Change Demate
export const FETCH_CHANGE_DEMATE_BEGIN = 'FETCH_CHANGE_DEMATE_BEGIN'
export const FETCH_CHANGE_DEMATE_SUCCESS = 'FETCH_CHANGE_DEMATE_SUCCESS'

export const fetchChangeDemateBegin = () => ({
    type: FETCH_CHANGE_DEMATE_BEGIN
})

export const fetchChangeDemateSuccess = data => ({
    type: FETCH_CHANGE_DEMATE_SUCCESS,
    payload: { data }
})

// Change Address
export const FETCH_CHANGE_ADDRESS_BEGIN = 'FETCH_CHANGE_ADDRESS_BEGIN'
export const FETCH_CHANGE_ADDRESS_SUCCESS = 'FETCH_CHANGE_ADDRESS_SUCCESS'

export const fetchChangeAddressBegin = () => ({
    type: FETCH_CHANGE_ADDRESS_BEGIN
})

export const fetchChangeAddressSuccess = data => ({
    type: FETCH_CHANGE_ADDRESS_SUCCESS,
    payload: { data }
})

// Change Email Mobile
export const FETCH_CHANGE_EMAIL_MOBILE_BEGIN = 'FETCH_CHANGE_EMAIL_MOBILE_BEGIN'
export const FETCH_CHANGE_EMAIL_MOBILE_SUCCESS = 'FETCH_CHANGE_EMAIL_MOBILE_SUCCESS'

export const fetchChangeEmailMobileBegin = () => ({
    type: FETCH_CHANGE_EMAIL_MOBILE_BEGIN
})

export const fetchChangeEmailMobileSuccess = data => ({
    type: FETCH_CHANGE_EMAIL_MOBILE_SUCCESS,
    payload: { data }
})

//EKYC Client Code

export const FETCH_EKYC_CLIENT_CODE_BEGIN = 'FETCH_EKYC_CLIENT_CODE_BEGIN'
export const FETCH_EKYC_CLIENT_CODE_SUCCESS = 'FETCH_EKYC_CLIENT_CODE_SUCCESS'
export const FETCH_EKYC_CLIENT_CODE_FAILURE = 'FETCH_EKYC_CLIENT_CODE_FAILURE'

export const fetchEKYCClientCodeBegin = () => ({
    type: FETCH_EKYC_CLIENT_CODE_BEGIN
})

export const fetchEKYCClientCodeSuccess = data => ({
    type: FETCH_EKYC_CLIENT_CODE_SUCCESS,
    payload: { data }
})

export const fetchEKYCClientCodeFailed = error => ({
    type: FETCH_EKYC_CLIENT_CODE_FAILURE,
    payload: { error }
})

//BOOKMARKED

export const FETCH_BOOKMARKED_BEGIN = 'FETCH_BOOKMARKED_BEGIN'
export const FETCH_BOOKMARKED_SUCCESS = 'FETCH_BOOKMARKED_SUCCESS'
export const FETCH_BOOKMARKED_FAILURE = 'FETCH_BOOKMARKED_FAILURE'

export const fetchBookmarkedBegin = () => ({
    type: FETCH_BOOKMARKED_BEGIN
})

export const fetchBookmarkedSuccess = data => ({
    type: FETCH_BOOKMARKED_SUCCESS,
    payload: { data }
})

export const fetchBookmarkedFailed = error => ({
    type: FETCH_BOOKMARKED_FAILURE,
    payload: { error }
})

//KYC Remark

export const FETCH_KYC_REMARK_BEGIN = 'FETCH_KYC_REMARK_BEGIN'
export const FETCH_KYC_REMARK_SUCCESS = 'FETCH_KYC_REMARK_SUCCESS'
export const FETCH_KYC_REMARK_FAILURE = 'FETCH_KYC_REMARK_FAILURE'

export const fetchKYCRemarkBegin = () => ({
    type: FETCH_KYC_REMARK_BEGIN
})

export const fetchKYCRemarkSuccess = data => ({
    type: FETCH_KYC_REMARK_SUCCESS,
    payload: { data }
})

export const fetchKYCRemarkFailed = error => ({
    type: FETCH_KYC_REMARK_FAILURE,
    payload: { error }
})


//Leave
export const FETCH_LEAVE_BEGIN = 'FETCH_LEAVE_BEGIN'
export const FETCH_LEAVE_SUCCESS = 'FETCH_LEAVE_SUCCESS'
export const FETCH_LEAVE_FAILURE = 'FETCH_LEAVE_FAILURE'

export const fetchLeaveBegin = () => ({
    type: FETCH_LEAVE_BEGIN
})

export const fetchLeaveSuccess = data => ({
    type: FETCH_LEAVE_SUCCESS,
    payload: { data }
})

export const fetchLeaveFailed = error => ({
    type: FETCH_LEAVE_FAILURE,
    payload: { error }
})


//CourierInward
export const FETCH_COURIER_INWARD_BEGIN = 'FETCH_COURIER_INWARD_BEGIN'
export const FETCH_COURIER_INWARD_SUCCESS = 'FETCH_COURIER_INWARD_SUCCESS'
export const FETCH_COURIER_INWARD_FAILURE = 'FETCH_COURIER_INWARD_FAILURE'

export const fetchCourierInwardBegin = () => ({
    type: FETCH_COURIER_INWARD_BEGIN
})

export const fetchCourierInwardSuccess = data => ({
    type: FETCH_COURIER_INWARD_SUCCESS,
    payload: { data }
})

export const fetchCourierInwardFailed = error => ({
    type: FETCH_COURIER_INWARD_FAILURE,
    payload: { error }
})

//CourierOutward
export const FETCH_COURIER_OUTWARD_BEGIN = 'FETCH_COURIER_OUTWARD_BEGIN'
export const FETCH_COURIER_OUTWARD_SUCCESS = 'FETCH_COURIER_OUTWARD_SUCCESS'
export const FETCH_COURIER_OUTWARD_FAILURE = 'FETCH_COURIER_OUTWARD_FAILURE'

export const fetchCourierOutwardBegin = () => ({
    type: FETCH_COURIER_OUTWARD_BEGIN
})

export const fetchCourierOutwardSuccess = data => ({
    type: FETCH_COURIER_OUTWARD_SUCCESS,
    payload: { data }
})

export const fetchCourierOutwardFailed = error => ({
    type: FETCH_COURIER_OUTWARD_FAILURE,
    payload: { error }
})

//CourierNameList
export const FETCH_COURIER_NAME_LIST_BEGIN = 'FETCH_COURIER_NAME_LIST_BEGIN'
export const FETCH_COURIER_NAME_LIST_SUCCESS = 'FETCH_COURIER_NAME_LIST_SUCCESS'
export const FETCH_COURIER_NAME_LIST_FAILURE = 'FETCH_COURIER_NAME_LIST_FAILURE'

export const fetchCourierNameListBegin = () => ({
    type: FETCH_COURIER_NAME_LIST_BEGIN
})

export const fetchCourierNameListSuccess = data => ({
    type: FETCH_COURIER_NAME_LIST_SUCCESS,
    payload: { data }
})

export const fetchCourierNameListFailed = error => ({
    type: FETCH_COURIER_NAME_LIST_FAILURE,
    payload: { error }
})

//CourierNameList
export const FETCH_COURIER_TYPE_BEGIN = 'FETCH_COURIER_TYPE_BEGIN'
export const FETCH_COURIER_TYPE_SUCCESS = 'FETCH_COURIER_TYPE_SUCCESS'
export const FETCH_COURIER_TYPE_FAILURE = 'FETCH_COURIER_TYPE_FAILURE'

export const fetchCourierTypeBegin = () => ({
    type: FETCH_COURIER_TYPE_BEGIN
})

export const fetchCourierTypeSuccess = data => ({   
    type: FETCH_COURIER_TYPE_SUCCESS,
    payload: { data }
})

export const fetchCourierTypeFailed = error => ({
    type: FETCH_COURIER_TYPE_FAILURE,
    payload: { error }
})

//Courier Sender
export const FETCH_COURIER_SENDER_BEGIN = 'FETCH_COURIER_SENDER_BEGIN'
export const FETCH_COURIER_SENDER_SUCCESS = 'FETCH_COURIER_SENDER_SUCCESS'
export const FETCH_COURIER_SENDER_FAILURE = 'FETCH_COURIER_SENDER_FAILURE'

export const fetchCourierSenderBegin = () => ({
    type: FETCH_COURIER_SENDER_BEGIN
})

export const fetchCourierSenderSuccess = data => ({   
    type: FETCH_COURIER_SENDER_SUCCESS,
    payload: { data }
})

export const fetchCourierSenderFailed = error => ({
    type: FETCH_COURIER_SENDER_FAILURE,
    payload: { error }
})


//add TENDER order
export const ADD_TENDERORDER_BEGIN = 'ADD_TENDERORDER_BEGIN'
export const ADD_TENDERORDER_SUCCESS = 'ADD_TENDERORDER_SUCCESS'
export const ADD_TENDERORDER_FAILURE = 'ADD_TENDERORDER_FAILURE'

export const addTENDEROrderBegin = () => ({
    type: ADD_TENDERORDER_BEGIN
})

export const addTENDEROrderSuccess = ipo => ({
    type: ADD_TENDERORDER_SUCCESS,
    payload: { ipo }
})

export const addTENDEROrderFailure = error => ({
    type: ADD_TENDERORDER_FAILURE,
    payload: { error }
})

//add ncb order
export const ADD_NCBORDER_BEGIN = 'ADD_NCBORDER_BEGIN'
export const ADD_NCBORDER_SUCCESS = 'ADD_NCBORDER_SUCCESS'
export const ADD_NCBORDER_FAILURE = 'ADD_NCBORDER_FAILURE'

export const addNCBOrderBegin = () => ({
    type: ADD_NCBORDER_BEGIN
})

export const addNCBOrderSuccess = ipo => ({
    type: ADD_NCBORDER_SUCCESS,
    payload: { ipo }
})

export const addNCBOrderFailure = error => ({
    type: ADD_NCBORDER_FAILURE,
    payload: { error }
})

//IPO Login Error Log
export const FETCH_IPO_LOGIN_ERROR_LOG_BEGIN = 'FETCH_IPO_LOGIN_ERROR_LOG_BEGIN'
export const FETCH_IPO_LOGIN_ERROR_LOG_SUCCESS = 'FETCH_IPO_LOGIN_ERROR_LOG_SUCCESS'
export const FETCH_IPO_LOGIN_ERROR_LOG_FAILURE = 'FETCH_IPO_LOGIN_ERROR_LOG_FAILURE'

export const fetchIPOLoginErrorLogBegin = () => ({
    type: FETCH_IPO_LOGIN_ERROR_LOG_BEGIN
})

export const fetchIPOLoginErrorLogSuccess = data => ({
    type: FETCH_IPO_LOGIN_ERROR_LOG_SUCCESS,
    payload: { data }
})

export const fetchIPOLoginErrorLogFailed = error => ({
    type: FETCH_IPO_LOGIN_ERROR_LOG_FAILURE,
    payload: { error }
})


//IPO Accord Log
export const FETCH_IPO_ACCORD_LOG_BEGIN = 'FETCH_IPO_ACCORD_LOG_BEGIN'
export const FETCH_IPO_ACCORD_LOG_SUCCESS = 'FETCH_IPO_ACCORD_LOG_SUCCESS'
export const FETCH_IPO_ACCORD_LOG_FAILURE = 'FETCH_IPO_ACCORD_LOG_FAILURE'

export const fetchIPOAccordLogBegin = () => ({
    type: FETCH_IPO_ACCORD_LOG_BEGIN
})

export const fetchIPOAccordLogSuccess = data => ({
    type: FETCH_IPO_ACCORD_LOG_SUCCESS,
    payload: { data }
})

export const fetchIPOAccordLogFailed = error => ({
    type: FETCH_IPO_ACCORD_LOG_FAILURE,
    payload: { error }
})


//Notes List

export const FETCH_NOTES_BEGIN = '  FETCH_NOTES_BEGIN'
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS'
export const FETCH_NOTES_FAILURE = 'FETCH_NOTES_FAILURE'

export const fetchNotesBegin = () => ({
    type: FETCH_NOTES_BEGIN
})

export const fetchNotesSuccess = data => ({
    type: FETCH_NOTES_SUCCESS,
    payload: { data }
})

export const fetchNotesFailed = error => ({
    type: FETCH_NOTES_FAILURE,
    payload: { error }
})

//Digital Docs

export const FETCH_DIGITAL_DOCS_BEGIN = 'FETCH_DIGITAL_DOCS__BEGIN'
export const FETCH_DIGITAL_DOCS_SUCCESS = 'FETCH_DIGITAL_DOCS__SUCCESS'
export const FETCH_DIGITAL_DOCS_FAILURE = 'FETCH_DIGITAL_DOCS__FAILURE'

export const fetchDigitalDocsBegin = () => ({
    type: FETCH_DIGITAL_DOCS_BEGIN
})

export const fetchDigitalDocsSuccess = data => ({
    type: FETCH_DIGITAL_DOCS_SUCCESS,
    payload: { data }
})

export const fetchDigitalDocsFailed = error => ({
    type: FETCH_DIGITAL_DOCS_FAILURE,
    payload: { error }
})

//Digital Docs File Lists

export const FETCH_DIGITAL_DOCS_FILE_BEGIN = 'FETCH_DIGITAL_DOCS_FILE_BEGIN'
export const FETCH_DIGITAL_DOCS_FILE_SUCCESS = 'FETCH_DIGITAL_DOCS_FILE_SUCCESS'
export const FETCH_DIGITAL_DOCS_FILE_FAILURE = 'FETCH_DIGITAL_DOCS_FILE_FAILURE'

export const fetchDigitalDocsFileBegin = () => ({
    type: FETCH_DIGITAL_DOCS_FILE_BEGIN
})

export const fetchDigitalDocsFileSuccess = data => ({
    type: FETCH_DIGITAL_DOCS_FILE_SUCCESS,
    payload: { data }
})

export const fetchDigitalDocsFileFailed = error => ({
    type: FETCH_DIGITAL_DOCS_FILE_FAILURE,
    payload: { error }
})

//Calendar
export const FETCH_CALENDAR_BEGIN = 'FETCH_CALENDAR_BEGIN'
export const FETCH_CALENDAR_SUCCESS = 'FETCH_CALENDAR_SUCCESS'
export const FETCH_CALENDAR_FAILURE = 'FETCH_CALENDAR_FAILURE'

export const fetchCalendarBegin = () => ({
    type: FETCH_CALENDAR_BEGIN
})

export const fetchCalendarSuccess = data => ({
    type: FETCH_CALENDAR_SUCCESS,
    payload: { data }
})

export const fetchCalendarFailed = error => ({
    type: FETCH_CALENDAR_FAILURE,
    payload: { error }
})

//Brain Recent Devices
export const FETCH_BRAIN_RECENT_DEVICE_BEGIN = 'FETCH_BRAIN_RECENT_DEVICE_BEGIN'
export const FETCH_BRAIN_RECENT_DEVICE_SUCCESS = 'FETCH_BRAIN_RECENT_DEVICE_SUCCESS'
export const FETCH_BRAIN_RECENT_DEVICE_FAILURE = 'FETCH_BRAIN_RECENT_DEVICE_FAILURE'

export const fetchBrainRecentDeviceBegin = () => ({
    type: FETCH_BRAIN_RECENT_DEVICE_BEGIN
})

export const fetchBrainRecentDeviceSuccess = data => ({
    type: FETCH_BRAIN_RECENT_DEVICE_SUCCESS,
    payload: { data }
})

export const fetchBrainRecentDeviceFailed = error => ({
    type: FETCH_BRAIN_RECENT_DEVICE_FAILURE,
    payload: { error }
})

//BOSS Recent Devices
export const FETCH_BOSS_RECENT_DEVICE_BEGIN = 'FETCH_BOSS_RECENT_DEVICE_BEGIN'
export const FETCH_BOSS_RECENT_DEVICE_SUCCESS = 'FETCH_BOSS_RECENT_DEVICE_SUCCESS'
export const FETCH_BOSS_RECENT_DEVICE_FAILURE = 'FETCH_BOSS_RECENT_DEVICE_FAILURE'

export const fetchBOSSRecentDeviceBegin = () => ({
    type: FETCH_BOSS_RECENT_DEVICE_BEGIN
})

export const fetchBOSSRecentDeviceSuccess = data => ({
    type: FETCH_BOSS_RECENT_DEVICE_SUCCESS,
    payload: { data }
})

export const fetchBOSSRecentDeviceFailed = error => ({
    type: FETCH_BOSS_RECENT_DEVICE_FAILURE,
    payload: { error }
})

//MEnu
export const FETCH_MENU_BEGIN = 'FETCH_MENU_BEGIN'
export const FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS'
export const FETCH_MENU_FAILURE = 'FETCH_MENU_FAILURE'

export const fetchMenuBegin = () => ({
    type: FETCH_MENU_BEGIN
})

export const fetchMenuSuccess = data => ({
    type: FETCH_MENU_SUCCESS,
    payload: { data }
})

export const fetchMenuFailed = error => ({
    type: FETCH_MENU_FAILURE,
    payload: { error }
})

//Email Template
export const FETCH_EMAIL_TEMPLATE_BEGIN = 'FETCH_EMAIL_TEMPLATE_BEGIN'
export const FETCH_EMAIL_TEMPLATE_SUCCESS = 'FETCH_EMAIL_TEMPLATE_SUCCESS'
export const FETCH_EMAIL_TEMPLATE_FAILURE = 'FETCH_EMAIL_TEMPLATE_FAILURE'

export const fetchEmailTemplateBegin = () => ({
    type: FETCH_EMAIL_TEMPLATE_BEGIN
})

export const fetchEmailTemplateSuccess = data => ({
    type: FETCH_EMAIL_TEMPLATE_SUCCESS,
    payload: { data }
})

export const fetchEmailTemplateFailed = error => ({
    type: FETCH_EMAIL_TEMPLATE_FAILURE,
    payload: { error }
})


//Email Template Subject
export const FETCH_EMAIL_TEMPLATE_SUBJECT_BEGIN = 'FETCH_EMAIL_TEMPLATE_SUBJECT_BEGIN'
export const FETCH_EMAIL_TEMPLATE_SUBJECT_SUCCESS = 'FETCH_EMAIL_TEMPLATE_SUBJECT_SUCCESS'
export const FETCH_EMAIL_TEMPLATE_SUBJECT_FAILURE = 'FETCH_EMAIL_TEMPLATE_SUBJECT_FAILURE'


export const fetchEmailTemplateSubjectBegin = () => ({
    type: FETCH_EMAIL_TEMPLATE_SUBJECT_BEGIN
})

export const fetchEmailTemplateSubjectSuccess = data => ({
    type: FETCH_EMAIL_TEMPLATE_SUBJECT_SUCCESS,
    payload: { data }
})

export const fetchEmailTemplateSubjectFailed = error => ({
    type: FETCH_EMAIL_TEMPLATE_SUBJECT_FAILURE,
    payload: { error }
})

//Email Template Tags
export const FETCH_EMAIL_TEMPLATE_TAGS_BEGIN = 'FETCH_EMAIL_TEMPLATE_TAGS_BEGIN'
export const FETCH_EMAIL_TEMPLATE_TAGS_SUCCESS = 'FETCH_EMAIL_TEMPLATE_TAGS_SUCCESS'
export const FETCH_EMAIL_TEMPLATE_TAGS_FAILURE = 'FETCH_EMAIL_TEMPLATE_TAGS_FAILURE'


export const fetchEmailTemplateTagsBegin = () => ({
    type: FETCH_EMAIL_TEMPLATE_TAGS_BEGIN
})

export const fetchEmailTemplateTagsSuccess = data => ({
    type: FETCH_EMAIL_TEMPLATE_TAGS_SUCCESS,
    payload: { data }
})

export const fetchEmailTemplateTagsFailed = error => ({
    type: FETCH_EMAIL_TEMPLATE_TAGS_FAILURE,
    payload: { error }
})

//notification
export const FETCH_NOTIFICATION_BEGIN = 'FETCH_NOTIFICATION_BEGIN'
export const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS'
export const FETCH_NOTIFICATION_FAILURE = 'FETCH_NOTIFICATION_FAILURE'


export const fetchNotificationBegin = () => ({
    type: FETCH_NOTIFICATION_BEGIN
})

export const fetchNotificationSuccess = data => ({
    type: FETCH_NOTIFICATION_SUCCESS,
    payload: { data }
})

export const fetchNotificationFailed = error => ({
    type: FETCH_NOTIFICATION_FAILURE,
    payload: { error }
})

//All notification
export const FETCH_ALL_NOTIFICATION_BEGIN = 'FETCH_ALL_NOTIFICATION_BEGIN'
export const FETCH_ALL_NOTIFICATION_SUCCESS = 'FETCH_ALL_NOTIFICATION_SUCCESS'
export const FETCH_ALL_NOTIFICATION_FAILURE = 'FETCH_ALL_NOTIFICATION_FAILURE'


export const fetchAllNotificationBegin = () => ({
    type: FETCH_ALL_NOTIFICATION_BEGIN
})

export const fetchAllNotificationSuccess = data => ({
    type: FETCH_ALL_NOTIFICATION_SUCCESS,
    payload: { data }
})

export const fetchAllNotificationFailed = error => ({
    type: FETCH_ALL_NOTIFICATION_FAILURE,
    payload: { error }
})

//notification
export const FETCH_REMINDER_NOTIFICATION_BEGIN = 'FETCH_REMINDER_NOTIFICATION_BEGIN'
export const FETCH_REMINDER_NOTIFICATION_SUCCESS = 'FETCH_REMINDER_NOTIFICATION_SUCCESS'
export const FETCH_REMINDER_NOTIFICATION_FAILURE = 'FETCH_REMINDER_NOTIFICATION_FAILURE'


export const fetchReminderNotificationBegin = () => ({
    type: FETCH_REMINDER_NOTIFICATION_BEGIN
})

export const fetchReminderNotificationSuccess = data => ({
    type: FETCH_REMINDER_NOTIFICATION_SUCCESS,
    payload: { data }
})

export const fetchReminderNotificationFailed = error => ({
    type: FETCH_REMINDER_NOTIFICATION_FAILURE,
    payload: { error }
})


//Circular

export const FETCH_CIRCULAR_BEGIN = 'FETCH_CIRCULAR_BEGIN'
export const FETCH_CIRCULAR_SUCCESS = 'FETCH_CIRCULAR_SUCCESS'
export const FETCH_CIRCULAR_FAILURE = 'FETCH_CIRCULAR_FAILURE'

export const fetchCircularBegin = () => ({
    type: FETCH_CIRCULAR_BEGIN
})

export const fetchCircularSuccess = data => ({
    type: FETCH_CIRCULAR_SUCCESS,
    payload: { data }
})

export const fetchCircularFailed = error => ({
    type: FETCH_CIRCULAR_FAILURE,
    payload: { error }
})

//Circular CATEGORY

export const FETCH_CIRCULAR_CATEGORY_BEGIN = 'FETCH_CIRCULAR_CATEGORY_BEGIN'
export const FETCH_CIRCULAR_CATEGORY_SUCCESS = 'FETCH_CIRCULAR_CATEGORY_SUCCESS'
export const FETCH_CIRCULAR_CATEGORY_FAILURE = 'FETCH_CIRCULAR_CATEGORY_FAILURE'

export const fetchCircularCategoryBegin = () => ({
    type: FETCH_CIRCULAR_CATEGORY_BEGIN
})

export const fetchCircularCategorySuccess = data => ({
    type: FETCH_CIRCULAR_CATEGORY_SUCCESS,
    payload: { data }
})

export const fetchCircularCategoryFailed = error => ({
    type: FETCH_CIRCULAR_CATEGORY_FAILURE,
    payload: { error }
})

//Circular RECENT POST

export const FETCH_CIRCULAR_RECENT_POST_BEGIN = 'FETCH_CIRCULAR_RECENT_POST_BEGIN'
export const FETCH_CIRCULAR_RECENT_POST_SUCCESS = 'FETCH_CIRCULAR_RECENT_POST_SUCCESS'
export const FETCH_CIRCULAR_RECENT_POST_FAILURE = 'FETCH_CIRCULAR_RECENT_POST_FAILURE'

export const fetchCircularRecentPostBegin = () => ({
    type: FETCH_CIRCULAR_RECENT_POST_BEGIN
})

export const fetchCircularRecentPostSuccess = data => ({
    type: FETCH_CIRCULAR_RECENT_POST_SUCCESS,
    payload: { data }
})

export const fetchCircularRecentPostFailed = error => ({
    type: FETCH_CIRCULAR_RECENT_POST_FAILURE,
    payload: { error }
})

//Finsetu News List

export const FETCH_FINSETU_NEWS_LIST_BEGIN = 'FETCH_FINSETU_NEWS_LIST_BEGIN'
export const FETCH_FINSETU_NEWS_LIST_SUCCESS = 'FETCH_FINSETU_NEWS_LIST_SUCCESS'
export const FETCH_FINSETU_NEWS_LIST_FAILURE = 'FETCH_FINSETU_NEWS_LIST_FAILURE'

export const fetchFinsetuNewsListBegin = () => ({
    type: FETCH_FINSETU_NEWS_LIST_BEGIN
})

export const fetchFinsetuNewsListSuccess = data => ({
    type: FETCH_FINSETU_NEWS_LIST_SUCCESS,
    payload: { data }
})

export const fetchFinsetuNewsListFailed = error => ({
    type: FETCH_FINSETU_NEWS_LIST_FAILURE,
    payload: { error }
})


//Finsetu NEWS tags

export const FETCH_FINSETU_NEWS_TAG_BEGIN = 'FETCH_FINSETU_NEWS_TAG_BEGIN'
export const FETCH_FINSETU_NEWS_TAG_SUCCESS = 'FETCH_FINSETU_NEWS_TAG_SUCCESS'
export const FETCH_FINSETU_NEWS_TAG_FAILURE = 'FETCH_FINSETU_NEWS_TAG_FAILURE'

export const fetchFinsetuNewsTagBegin = () => ({
    type: FETCH_FINSETU_NEWS_TAG_BEGIN
})

export const fetchFinsetuNewsTagSuccess = data => ({
    type: FETCH_FINSETU_NEWS_TAG_SUCCESS,
    payload: { data }
})

export const fetchFinsetuNewsTagFailed = error => ({
    type: FETCH_FINSETU_NEWS_TAG_FAILURE,
    payload: { error }
})


//Finsetu NEWS Listing

export const FETCH_FINSETU_NEWS_LISTING_BEGIN = 'FETCH_FINSETU_NEWS_LISTING_BEGIN'
export const FETCH_FINSETU_NEWS_LISTING_SUCCESS = 'FETCH_FINSETU_NEWS_LISTING_SUCCESS'
export const FETCH_FINSETU_NEWS_LISTING_FAILURE = 'FETCH_FINSETU_NEWS_LISTING_FAILURE'

export const fetchFinsetuNewsListingBegin = () => ({
    type: FETCH_FINSETU_NEWS_LISTING_BEGIN
})

export const fetchFinsetuNewsListingSuccess = data => ({
    type: FETCH_FINSETU_NEWS_LISTING_SUCCESS,
    payload: { data }
})

export const fetchFinsetuNewsListingFailed = error => ({
    type: FETCH_FINSETU_NEWS_LISTING_FAILURE,
    payload: { error }
})

//Finsetu Investor Grouping
export const FETCH_INVESTOR_GROUPING_BEGIN = 'FETCH_INVESTOR_GROUPING_BEGIN'
export const FETCH_INVESTOR_GROUPING_SUCCESS = 'FETCH_INVESTOR_GROUPING_SUCCESS'
export const FETCH_INVESTOR_GROUPING_FAILURE = 'FETCH_INVESTOR_GROUPING_FAILURE'

export const fetchInvestorGroupingBegin = () => ({
    type: FETCH_INVESTOR_GROUPING_BEGIN
})

export const fetchInvestorGroupingSuccess = data => ({
    type: FETCH_INVESTOR_GROUPING_SUCCESS,
    payload: { data }
})

export const fetchInvestorGroupingFailed = error => ({
    type: FETCH_INVESTOR_GROUPING_FAILURE,
    payload: { error }
})

//Brokerage Charges
export const FETCH_BROKERAGE_CHARGES_BEGIN = 'FETCH_BROKERAGE_CHARGES_BEGIN'
export const FETCH_BROKERAGE_CHARGES_SUCCESS = 'FETCH_BROKERAGE_CHARGES_SUCCESS'
export const FETCH_BROKERAGE_CHARGES_FAILURE = 'FETCH_BROKERAGE_CHARGES_FAILURE'

export const fetchBrokerageChargesBegin = () => ({
    type: FETCH_BROKERAGE_CHARGES_BEGIN
})

export const fetchBrokerageChargesSuccess = data => ({
    type: FETCH_BROKERAGE_CHARGES_SUCCESS,
    payload: { data }
})

export const fetchBrokerageChargesFailed = error => ({
    type: FETCH_BROKERAGE_CHARGES_FAILURE,
    payload: { error }
})


//add Brain Client Profile
export const FETCH_BRAIN_CLIENT_PROFILE_BEGIN = 'FETCH_BRAIN_CLIENT_PROFILE_BEGIN'
export const FETCH_BRAIN_CLIENT_PROFILE_SUCCESS = 'FETCH_BRAIN_CLIENT_PROFILE_SUCCESS'
export const FETCH_BRAIN_CLIENT_PROFILE_FAILURE = 'FETCH_BRAIN_CLIENT_PROFILE_FAILURE'

export const fetchBrainClientProfileBegin = () => ({
    type: FETCH_BRAIN_CLIENT_PROFILE_BEGIN
})

export const fetchBrainClientProfileSuccess = data => ({
    type: FETCH_BRAIN_CLIENT_PROFILE_SUCCESS,
    payload: { data }
})

export const fetchBrainClientProfileFailed = error => ({
    type: FETCH_BRAIN_CLIENT_PROFILE_FAILURE,
    payload: { error }
})



//Back Office Ledger
export const FETCH_BACK_OFFICE_LEDGER_BEGIN   = 'FETCH_BACK_OFFICE_LEDGER_BEGIN'  
export const FETCH_BACK_OFFICE_LEDGER_SUCCESS = 'FETCH_BACK_OFFICE_LEDGER_SUCCESS'
export const FETCH_BACK_OFFICE_LEDGER_FAILURE = 'FETCH_BACK_OFFICE_LEDGER_FAILURE'

export const fetchBackOfficeLedgerBegin = () => ({
    type: FETCH_BACK_OFFICE_LEDGER_BEGIN
})

export const fetchBackOfficeLedgerSuccess = data => ({
    type: FETCH_BACK_OFFICE_LEDGER_SUCCESS,
    payload: { data }
})

export const fetchBackOfficeLedgerFailure = error => ({
    type: FETCH_BACK_OFFICE_LEDGER_FAILURE,
    payload: { error }
})


//Back Office Position
export const FETCH_BACK_OFFICE_POSITION_BEGIN   = 'FETCH_BACK_OFFICE_POSITION_BEGIN'  
export const FETCH_BACK_OFFICE_POSITION_SUCCESS = 'FETCH_BACK_OFFICE_POSITION_SUCCESS'
export const FETCH_BACK_OFFICE_POSITION_FAILURE = 'FETCH_BACK_OFFICE_POSITION_FAILURE'

export const fetchBackOfficePositionBegin = () => ({
    type: FETCH_BACK_OFFICE_POSITION_BEGIN
})

export const fetchBackOfficePositionSuccess = data => ({
    type: FETCH_BACK_OFFICE_POSITION_SUCCESS,
    payload: { data }
})

export const fetchBackOfficePositionFailure = error => ({
    type: FETCH_BACK_OFFICE_POSITION_FAILURE,
    payload: { error }
})



//Back Office Holding
export const FETCH_BACK_OFFICE_HOLDING_BEGIN   = 'FETCH_BACK_OFFICE_HOLDING_BEGIN'  
export const FETCH_BACK_OFFICE_HOLDING_SUCCESS = 'FETCH_BACK_OFFICE_HOLDING_SUCCESS'
export const FETCH_BACK_OFFICE_HOLDING_FAILURE = 'FETCH_BACK_OFFICE_HOLDING_FAILURE'

export const fetchBackOfficeHoldingBegin = () => ({
    type: FETCH_BACK_OFFICE_HOLDING_BEGIN
})

export const fetchBackOfficeHoldingSuccess = data => ({
    type: FETCH_BACK_OFFICE_HOLDING_SUCCESS,
    payload: { data }
})

export const fetchBackOfficeHoldingFailure = error => ({
    type: FETCH_BACK_OFFICE_HOLDING_FAILURE,
    payload: { error }
})