
import * as ACTIONS from '../../actions/reports'

const initialState = {
    Email: [],
    Folder: [],
    Blocklist: [],
    loading: false,
    error: ''
}

const Email = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_EMAIL_BEGIN:
            return {
                ...state,
                Email: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                Email: action.payload.data
            }

        case ACTIONS.FETCH_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_FOLDER_BEGIN:
            return {
                ...state,
                Folder: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_FOLDER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                Folder: action.payload.data
            }

        case ACTIONS.FETCH_FOLDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_BLOCKLIST_BEGIN:
            return {
                ...state,
                Blocklist: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_BLOCKLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                Blocklist: action.payload.data
            }

        case ACTIONS.FETCH_BLOCKLIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default Email;