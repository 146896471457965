
import * as ACTIONS from '../../actions/reports/index';

const initialState = {
    holiday: [],
    loading: false,
    error: ''
}

const holiday = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_HOLIDAY_BEGIN:
            return {
                ...state,
                holiday: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_HOLIDAY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                holiday: action.payload.data
            }

        case ACTIONS.FETCH_HOLIDAY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default holiday;