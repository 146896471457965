const initialState = {
  chats: [],
  groupchats: [],
  contacts: [],
  department: [],
  userProfile: {},
  selectedUser: {},
  selectedDepartment: {}
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile }
    case 'GET_CHAT_CONTACTS':
      return { ...state, chats: [], contacts: action.data.contacts }
    case 'GET_CHAT_DEPARTMENT':
      return { ...state, groupchats: [], department: action.data.department }
    case 'SELECT_CHAT':
      return { ...state, selectedUser: action.data }
    case 'SELECT_DEPARTMENT_CHAT':
      return { ...state, selectedDepartment: action.data }
    case 'SEND_MSG':
      // ** Add new msg to chat
      const newMsg = action.data.response.chat
      return { ...state, selectedUser: { ...selectedUser, chat: newMsg } }
    case 'SET_CHATS':
      return { ...state, chats: action.data }
    case 'SET_DEPARTMENT_CHATS':
      return { ...state, groupchats: action.data }
    default:
      return state
  }
}

export default chatReducer
