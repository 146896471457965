
import * as ACTIONS from '../../actions/reports'

const initialState = {
    Module: [],
    loading: false,
    error: ''
}

const Module = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_MODULE_MASTER_BEGIN:
            return {
                ...state,
                Module: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_MODULE_MASTER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                Module: action.payload.data
            }

        case ACTIONS.FETCH_ACCOUNTOFFERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default Module;