//fetch Menu Master
export const FETCH_MENUMASTER_BEGIN = 'FETCH_MENUMASTER_BEGIN'
export const FETCH_MENUMASTER_SUCCESS = 'FETCH_MENUMASTER_SUCCESS'
export const FETCH_MENUMASTER_FAILURE = 'FETCH_MENUMASTER_FAILURE'

export const fetchMenuMasterBegin = () => ({
    type: FETCH_MENUMASTER_BEGIN
})

export const fetchMenuMasterSuccess = data => ({
    type: FETCH_MENUMASTER_SUCCESS,
    payload: { data }
})

export const fetchMenuMasterFailure = error => ({
    type: FETCH_MENUMASTER_FAILURE,
    payload: { error }
})

export const FETCH_PRIMARYMENU_BEGIN = 'FETCH_PRIMARYMENU_BEGIN'
export const FETCH_PRIMARYMENU_SUCCESS = 'FETCH_PRIMARYMENU_SUCCESS'
export const FETCH_PRIMARYMENU_FAILURE = 'FETCH_PRIMARYMENU_FAILURE'

export const fetchPrimaryMenuBegin = () => ({
    type: FETCH_PRIMARYMENU_BEGIN
})

export const fetchPrimaryMenuSuccess = data => ({
    type: FETCH_PRIMARYMENU_SUCCESS,
    payload: { data }
})

export const fetchPrimaryMenuFailure = error => ({
    type: FETCH_PRIMARYMENU_FAILURE,
    payload: { error }
})