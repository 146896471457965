
import * as ACTIONS from '../../actions/reports'

const initialState = {
    FinsetuNewsTag: [],
    loading: false,
    error: ''
}

const FinsetuNewsTag = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_FINSETU_NEWS_TAG_BEGIN:
            return {
                ...state,
                FinsetuNewsTag: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_FINSETU_NEWS_TAG_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                FinsetuNewsTag: action.payload.data
            }

        case ACTIONS.FETCH_FINSETU_NEWS_TAG_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default FinsetuNewsTag;