
import * as ACTIONS from '../../actions/reports'

const initialState = {
    BrokerageCharges: [],
    loading: false,
    error: ''
}

const BrokerageCharges = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_BROKERAGE_CHARGES_BEGIN:
            return {
                ...state,
                BrokerageCharges: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_BROKERAGE_CHARGES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                BrokerageCharges: action.payload.data
            }

        case ACTIONS.FETCH_BROKERAGE_CHARGES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default BrokerageCharges;