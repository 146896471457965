
import * as ACTIONS from '../../actions/reports/index';

const initialState = {
    Menu: [],
    loading: false,
    error: ''
}

const Menu = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_MENU_BEGIN:
            return {
                ...state,
                Menu: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                Menu: action.payload.data
            }

        case ACTIONS.FETCH_MENU_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default Menu;