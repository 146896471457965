import * as ACTIONS from '../../actions/reports'

const initialState = {
    IP: [],
}

const IPAddress = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_IPADDRESS_SAVE:
            return {
                ...state,
                IP: action.payload.data
            }
        default:
            return state
    }
}

export default IPAddress;