
import * as ACTIONS from '../../actions/reports'

const initialState = {
    EmailTemplateSubject: [],
    loading: false,
    error: ''
}

const EmailTemplateSubject = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_EMAIL_TEMPLATE_SUBJECT_BEGIN:
            return {
                ...state,
                EmailTemplateSubject: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_EMAIL_TEMPLATE_SUBJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                EmailTemplateSubject: action.payload.data
            }

        case ACTIONS.FETCH_EMAIL_TEMPLATE_SUBJECT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default EmailTemplateSubject;