
import * as ACTIONS from '../../actions/reports/index';

const initialState = {
    ChangeEmailMobile: [],
    loading: false,
    error: ''
}

const ChangeEmailMobile = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_CHANGE_EMAIL_MOBILE_BEGIN:
            return {
                ...state,
                ChangeEmailMobile: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_CHANGE_EMAIL_MOBILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                ChangeEmailMobile: action.payload.data
            }

        default:
            return state
    }
}

export default ChangeEmailMobile;