import * as ACTIONS from '../../actions/reports'

const initialState = {
    empId: '',
    
}

const empid = (state = initialState, action) => {
  
    switch (action.type) {
     

        case ACTIONS.FETCH_EMPID_SUCCESS:
            return {
                ...state,
                empId: action.payload.empId
            }

        default:
            return state
    }
}

export default empid