
//fetch RMS Client Code
export const FETCH_RMSCLIENTCODE_BEGIN = 'FETCH_RMSCLIENTCODE_BEGIN'
export const FETCH_RMSCLIENTCODE_SUCCESS = 'FETCH_RMSCLIENTCODE_SUCCESS'
export const FETCH_RMSCLIENTCODE_FAILURE = 'FETCH_RMSCLIENTCODE_FAILURE'

export const fetchRMSClientCodeBegin = () => ({
    type: FETCH_RMSCLIENTCODE_BEGIN
})

export const fetchRMSClientCodeSuccess = data => ({
    type: FETCH_RMSCLIENTCODE_SUCCESS,
    payload: { data }
})

export const fetchRMSClientCodeFailure = error => ({
    type: FETCH_RMSCLIENTCODE_FAILURE,
    payload: { error }
})

export const FETCH_RMSSYMPHONYCLIENTCODE_BEGIN = 'FETCH_RMSSYMPHONYCLIENTCODE_BEGIN'
export const FETCH_RMSSYMPHONYCLIENTCODE_SUCCESS = 'FETCH_RMSSYMPHONYCLIENTCODE_SUCCESS'
export const FETCH_RMSSYMPHONYCLIENTCODE_FAILURE = 'FETCH_RMSSYMPHONYCLIENTCODE_FAILURE'

export const fetchRMSSymphonyClientCodeBegin = () => ({
    type: FETCH_RMSSYMPHONYCLIENTCODE_BEGIN
})

export const fetchRMSSymphonyClientCodeSuccess = data => ({
    type: FETCH_RMSSYMPHONYCLIENTCODE_SUCCESS,
    payload: { data }
})

export const fetchRMSSymphonyClientCodeFailure = error => ({
    type: FETCH_RMSSYMPHONYCLIENTCODE_FAILURE,
    payload: { error }
})

export const FETCH_RMSSYMPHONYLASTDATE_BEGIN = 'FETCH_RMSSYMPHONYLASTDATE_BEGIN'
export const FETCH_RMSSYMPHONYLASTDATE_SUCCESS = 'FETCH_RMSSYMPHONYLASTDATE_SUCCESS'
export const FETCH_RMSSYMPHONYLASTDATE_FAILURE = 'FETCH_RMSSYMPHONYLASTDATE_FAILURE'

export const fetchRMSSymphonyLastDateBegin = () => ({
    type: FETCH_RMSSYMPHONYLASTDATE_BEGIN
})

export const fetchRMSSymphonyLastDateSuccess = data => ({
    type: FETCH_RMSSYMPHONYLASTDATE_SUCCESS,
    payload: { data }
})

export const fetchRMSSymphonyLastDateFailure = error => ({
    type: FETCH_RMSSYMPHONYLASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_RMSAPPSECLASTDATE_BEGIN = 'FETCH_RMSAPPSECLASTDATE_BEGIN'
export const FETCH_RMSAPPSECLASTDATE_SUCCESS = 'FETCH_RMSAPPSECLASTDATE_SUCCESS'
export const FETCH_RMSAPPSECLASTDATE_FAILURE = 'FETCH_RMSAPPSECLASTDATE_FAILURE'

export const fetchRMSAppSecLastDateBegin = () => ({
    type: FETCH_RMSAPPSECLASTDATE_BEGIN
})

export const fetchRMSAppSecLastDateSuccess = data => ({
    type: FETCH_RMSAPPSECLASTDATE_SUCCESS,
    payload: { data }
})

export const fetchRMSAppSecLastDateFailure = error => ({
    type: FETCH_RMSAPPSECLASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_RMSVARRATELASTDATE_BEGIN = 'FETCH_RMSVARRATELASTDATE_BEGIN'
export const FETCH_RMSVARRATELASTDATE_SUCCESS = 'FETCH_RMSVARRATELASTDATE_SUCCESS'
export const FETCH_RMSVARRATELASTDATE_FAILURE = 'FETCH_RMSVARRATELASTDATE_FAILURE'

export const fetchRMSVarRateLastDateBegin = () => ({
    type: FETCH_RMSVARRATELASTDATE_BEGIN
})

export const fetchRMSVarRateLastDateSuccess = data => ({
    type: FETCH_RMSVARRATELASTDATE_SUCCESS,
    payload: { data }
})

export const fetchRMSVarRateLastDateFailure = error => ({
    type: FETCH_RMSVARRATELASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_RMSAUCTIONLASTDATE_BEGIN = 'FETCH_RMSAUCTIONLASTDATE_BEGIN'
export const FETCH_RMSAUCTIONLASTDATE_SUCCESS = 'FETCH_RMSAUCTIONLASTDATE_SUCCESS'
export const FETCH_RMSAUCTIONLASTDATE_FAILURE = 'FETCH_RMSAUCTIONLASTDATE_FAILURE'

export const fetchRMSAuctionLastDateBegin = () => ({
    type: FETCH_RMSAUCTIONLASTDATE_BEGIN
})

export const fetchRMSAuctionLastDateSuccess = data => ({
    type: FETCH_RMSAUCTIONLASTDATE_SUCCESS,
    payload: { data }
})

export const fetchRMSAuctionLastDateFailure = error => ({
    type: FETCH_RMSAUCTIONLASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_RMSNUPURBCLASTDATE_BEGIN = 'FETCH_RMSNUPURBCLASTDATE_BEGIN'
export const FETCH_RMSNUPURBCLASTDATE_SUCCESS = 'FETCH_RMSNUPURBCLASTDATE_SUCCESS'
export const FETCH_RMSNUPURBCLASTDATE_FAILURE = 'FETCH_RMSNUPURBCLASTDATE_FAILURE'

export const fetchRMSNupurBcLastDateBegin = () => ({
    type: FETCH_RMSNUPURBCLASTDATE_BEGIN
})

export const fetchRMSNupurBcLastDateSuccess = data => ({
    type: FETCH_RMSNUPURBCLASTDATE_SUCCESS,
    payload: { data }
})

export const fetchRMSNupurBcLastDateFailure = error => ({
    type: FETCH_RMSNUPURBCLASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_RMSNUPURPDLASTDATE_BEGIN = 'FETCH_RMSNUPURPDLASTDATE_BEGIN'
export const FETCH_RMSNUPURPDLASTDATE_SUCCESS = 'FETCH_RMSNUPURPDLASTDATE_SUCCESS'
export const FETCH_RMSNUPURPDLASTDATE_FAILURE = 'FETCH_RMSNUPURPDLASTDATE_FAILURE'

export const fetchRMSNupurPdLastDateBegin = () => ({
    type: FETCH_RMSNUPURPDLASTDATE_BEGIN
})

export const fetchRMSNupurPdLastDateSuccess = data => ({
    type: FETCH_RMSNUPURPDLASTDATE_SUCCESS,
    payload: { data }
})

export const fetchRMSNupurPdLastDateFailure = error => ({
    type: FETCH_RMSNUPURPDLASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_RMSNUPURBHLASTDATE_BEGIN = 'FETCH_RMSNUPURBHLASTDATE_BEGIN'
export const FETCH_RMSNUPURBHLASTDATE_SUCCESS = 'FETCH_RMSNUPURBHLASTDATE_SUCCESS'
export const FETCH_RMSNUPURBHLASTDATE_FAILURE = 'FETCH_RMSNUPURBHLASTDATE_FAILURE'

export const fetchRMSNupurBhLastDateBegin = () => ({
    type: FETCH_RMSNUPURBHLASTDATE_BEGIN
})

export const fetchRMSNupurBhLastDateSuccess = data => ({
    type: FETCH_RMSNUPURBHLASTDATE_SUCCESS,
    payload: { data }
})

export const fetchRMSNupurBhLastDateFailure = error => ({
    type: FETCH_RMSNUPURBHLASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_RMSNUPURHLLASTDATE_BEGIN = 'FETCH_RMSNUPURHLLASTDATE_BEGIN'
export const FETCH_RMSNUPURHLLASTDATE_SUCCESS = 'FETCH_RMSNUPURHLLASTDATE_SUCCESS'
export const FETCH_RMSNUPURHLLASTDATE_FAILURE = 'FETCH_RMSNUPURHLLASTDATE_FAILURE'

export const fetchRMSNupurHlLastDateBegin = () => ({
    type: FETCH_RMSNUPURHLLASTDATE_BEGIN
})

export const fetchRMSNupurHlLastDateSuccess = data => ({
    type: FETCH_RMSNUPURHLLASTDATE_SUCCESS,
    payload: { data }
})

export const fetchRMSNupurHlLastDateFailure = error => ({
    type: FETCH_RMSNUPURHLLASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_RMSNUPURSMELASTDATE_BEGIN = 'FETCH_RMSNUPURSMELASTDATE_BEGIN'
export const FETCH_RMSNUPURSMELASTDATE_SUCCESS = 'FETCH_RMSNUPURSMELASTDATE_SUCCESS'
export const FETCH_RMSNUPURSMELASTDATE_FAILURE = 'FETCH_RMSNUPURSMELASTDATE_FAILURE'

export const fetchRMSNupurSmeLastDateBegin = () => ({
    type: FETCH_RMSNUPURSMELASTDATE_BEGIN
})

export const fetchRMSNupurSmeLastDateSuccess = data => ({
    type: FETCH_RMSNUPURSMELASTDATE_SUCCESS,
    payload: { data }
})

export const fetchRMSNupurSmeLastDateFailure = error => ({
    type: FETCH_RMSNUPURSMELASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_RMSFOBHAVCOPYLASTDATE_BEGIN = 'FETCH_RMSFOBHAVCOPYLASTDATE_BEGIN'
export const FETCH_RMSFOBHAVCOPYLASTDATE_SUCCESS = 'FETCH_RMSFOBHAVCOPYLASTDATE_SUCCESS'
export const FETCH_RMSFOBHAVCOPYLASTDATE_FAILURE = 'FETCH_RMSFOBHAVCOPYLASTDATE_FAILURE'

export const fetchRMSFOBhavCopyLastDateBegin = () => ({
    type: FETCH_RMSFOBHAVCOPYLASTDATE_BEGIN
})

export const fetchRMSFOBhavCopyLastDateSuccess = data => ({
    type: FETCH_RMSFOBHAVCOPYLASTDATE_SUCCESS,
    payload: { data }
})

export const fetchRMSFOBhavCopyLastDateFailure = error => ({
    type: FETCH_RMSFOBHAVCOPYLASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_RMSFOAELLASTDATE_BEGIN = 'FETCH_RMSFOAELLASTDATE_BEGIN'
export const FETCH_RMSFOAELLASTDATE_SUCCESS = 'FETCH_RMSFOAELLASTDATE_SUCCESS'
export const FETCH_RMSFOAELLASTDATE_FAILURE = 'FETCH_RMSFOAELLASTDATE_FAILURE'

export const fetchRMSFOAelLastDateBegin = () => ({
    type: FETCH_RMSFOAELLASTDATE_BEGIN
})

export const fetchRMSFOAelLastDateSuccess = data => ({
    type: FETCH_RMSFOAELLASTDATE_SUCCESS,
    payload: { data }
})

export const fetchRMSFOAelLastDateFailure = error => ({
    type: FETCH_RMSFOAELLASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_RMSFOSECBANLASTDATE_BEGIN = 'FETCH_RMSFOSECBANLASTDATE_BEGIN'
export const FETCH_RMSFOSECBANLASTDATE_SUCCESS = 'FETCH_RMSFOSECBANLASTDATE_SUCCESS'
export const FETCH_RMSFOSECBANLASTDATE_FAILURE = 'FETCH_RMSFOSECBANLASTDATE_FAILURE'

export const fetchRMSFOSecBanLastDateBegin = () => ({
    type: FETCH_RMSFOSECBANLASTDATE_BEGIN
})

export const fetchRMSFOSecBanLastDateSuccess = data => ({
    type: FETCH_RMSFOSECBANLASTDATE_SUCCESS,
    payload: { data }
})

export const fetchRMSFOSecBanLastDateFailure = error => ({
    type: FETCH_RMSFOSECBANLASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_RMSFOQTYFREEZELASTDATE_BEGIN = 'FETCH_RMSFOQTYFREEZELASTDATE_BEGIN'
export const FETCH_RMSFOQTYFREEZELASTDATE_SUCCESS = 'FETCH_RMSFOQTYFREEZELASTDATE_SUCCESS'
export const FETCH_RMSFOQTYFREEZELASTDATE_FAILURE = 'FETCH_RMSFOQTYFREEZELASTDATE_FAILURE'

export const fetchRMSFOQtyFreezeLastDateBegin = () => ({
    type: FETCH_RMSFOQTYFREEZELASTDATE_BEGIN
})

export const fetchRMSFOQtyFreezeLastDateSuccess = data => ({
    type: FETCH_RMSFOQTYFREEZELASTDATE_SUCCESS,
    payload: { data }
})

export const fetchRMSFOQtyFreezeLastDateFailure = error => ({
    type: FETCH_RMSFOQTYFREEZELASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_APPSEC_BEGIN = 'FETCH_APPSEC_BEGIN'
export const FETCH_APPSEC_SUCCESS = 'FETCH_APPSEC_SUCCESS'
export const FETCH_APPSEC_FAILURE = 'FETCH_APPSEC_FAILURE'

export const fetchAppSecBegin = () => ({
    type: FETCH_APPSEC_BEGIN
})

export const fetchAppSecSuccess = data => ({
    type: FETCH_APPSEC_SUCCESS,
    payload: { data }
})

export const fetchAppSecFailure = error => ({
    type: FETCH_APPSEC_FAILURE,
    payload: { error }
})

export const FETCH_VARRATE_BEGIN = 'FETCH_VARRATE_BEGIN'
export const FETCH_VARRATE_SUCCESS = 'FETCH_VARRATE_SUCCESS'
export const FETCH_VARRATE_FAILURE = 'FETCH_VARRATE_FAILURE'

export const fetchVarRateBegin = () => ({
    type: FETCH_VARRATE_BEGIN
})

export const fetchVarRateSuccess = data => ({
    type: FETCH_VARRATE_SUCCESS,
    payload: { data }
})

export const fetchVarRateFailure = error => ({
    type: FETCH_VARRATE_FAILURE,
    payload: { error }
})

export const FETCH_AUCTION_BEGIN = 'FETCH_AUCTION_BEGIN'
export const FETCH_AUCTION_SUCCESS = 'FETCH_AUCTION_SUCCESS'
export const FETCH_AUCTION_FAILURE = 'FETCH_AUCTION_FAILURE'

export const fetchAuctionBegin = () => ({
    type: FETCH_AUCTION_BEGIN
})

export const fetchAuctionSuccess = data => ({
    type: FETCH_AUCTION_SUCCESS,
    payload: { data }
})

export const fetchAuctionFailure = error => ({
    type: FETCH_AUCTION_FAILURE,
    payload: { error }
})

export const FETCH_CMNUPURPD_BEGIN = 'FETCH_CMNUPURPD_BEGIN'
export const FETCH_CMNUPURPD_SUCCESS = 'FETCH_CMNUPURPD_SUCCESS'
export const FETCH_CMNUPURPD_FAILURE = 'FETCH_CMNUPURPD_FAILURE'

export const fetchCmNupurPdBegin = () => ({
    type: FETCH_CMNUPURPD_BEGIN
})

export const fetchCmNupurPdSuccess = data => ({
    type: FETCH_CMNUPURPD_SUCCESS,
    payload: { data }
})

export const fetchCmNupurPdFailure = error => ({
    type: FETCH_CMNUPURPD_FAILURE,
    payload: { error }
})

export const FETCH_CMNUPURBC_BEGIN = 'FETCH_CMNUPURBC_BEGIN'
export const FETCH_CMNUPURBC_SUCCESS = 'FETCH_CMNUPURBC_SUCCESS'
export const FETCH_CMNUPURBC_FAILURE = 'FETCH_CMNUPURBC_FAILURE'

export const fetchCmNupurBcBegin = () => ({
    type: FETCH_CMNUPURBC_BEGIN
})

export const fetchCmNupurBcSuccess = data => ({
    type: FETCH_CMNUPURBC_SUCCESS,
    payload: { data }
})

export const fetchCmNupurBcFailure = error => ({
    type: FETCH_CMNUPURBC_FAILURE,
    payload: { error }
})

export const FETCH_CMNUPURBH_BEGIN = 'FETCH_CMNUPURBH_BEGIN'
export const FETCH_CMNUPURBH_SUCCESS = 'FETCH_CMNUPURBH_SUCCESS'
export const FETCH_CMNUPURBH_FAILURE = 'FETCH_CMNUPURBH_FAILURE'

export const fetchCmNupurBhBegin = () => ({
    type: FETCH_CMNUPURBH_BEGIN
})

export const fetchCmNupurBhSuccess = data => ({
    type: FETCH_CMNUPURBH_SUCCESS,
    payload: { data }
})

export const fetchCmNupurBhFailure = error => ({
    type: FETCH_CMNUPURBH_FAILURE,
    payload: { error }
})

export const FETCH_CMNUPURHL_BEGIN = 'FETCH_CMNUPURHL_BEGIN'
export const FETCH_CMNUPURHL_SUCCESS = 'FETCH_CMNUPURHL_SUCCESS'
export const FETCH_CMNUPURHL_FAILURE = 'FETCH_CMNUPURHL_FAILURE'

export const fetchCmNupurHlBegin = () => ({
    type: FETCH_CMNUPURHL_BEGIN
})

export const fetchCmNupurHlSuccess = data => ({
    type: FETCH_CMNUPURHL_SUCCESS,
    payload: { data }
})

export const fetchCmNupurHlFailure = error => ({
    type: FETCH_CMNUPURHL_FAILURE,
    payload: { error }
})

export const FETCH_CMNUPURSME_BEGIN = 'FETCH_CMNUPURSME_BEGIN'
export const FETCH_CMNUPURSME_SUCCESS = 'FETCH_CMNUPURSME_SUCCESS'
export const FETCH_CMNUPURSME_FAILURE = 'FETCH_CMNUPURSME_FAILURE'

export const fetchCmNupurSmeBegin = () => ({
    type: FETCH_CMNUPURSME_BEGIN
})

export const fetchCmNupurSmeSuccess = data => ({
    type: FETCH_CMNUPURSME_SUCCESS,
    payload: { data }
})

export const fetchCmNupurSmeFailure = error => ({
    type: FETCH_CMNUPURSME_FAILURE,
    payload: { error }
})

export const FETCH_FOQTYFREEZE_BEGIN = 'FETCH_FOQTYFREEZE_BEGIN'
export const FETCH_FOQTYFREEZE_SUCCESS = 'FETCH_FOQTYFREEZE_SUCCESS'
export const FETCH_FOQTYFREEZE_FAILURE = 'FETCH_FOQTYFREEZE_FAILURE'

export const fetchFoQtyFreezeBegin = () => ({
    type: FETCH_FOQTYFREEZE_BEGIN
})

export const fetchFoQtyFreezeSuccess = data => ({
    type: FETCH_FOQTYFREEZE_SUCCESS,
    payload: { data }
})

export const fetchFoQtyFreezeFailure = error => ({
    type: FETCH_FOQTYFREEZE_FAILURE,
    payload: { error }
})

export const FETCH_FOBHAVCOPY_BEGIN = 'FETCH_FOBHAVCOPY_BEGIN'
export const FETCH_FOBHAVCOPY_SUCCESS = 'FETCH_FOBHAVCOPY_SUCCESS'
export const FETCH_FOBHAVCOPY_FAILURE = 'FETCH_FOBHAVCOPY_FAILURE'

export const fetchFoBhavCopyBegin = () => ({
    type: FETCH_FOBHAVCOPY_BEGIN
})

export const fetchFoBhavCopySuccess = data => ({
    type: FETCH_FOBHAVCOPY_SUCCESS,
    payload: { data }
})

export const fetchFoBhavCopyFailure = error => ({
    type: FETCH_FOBHAVCOPY_FAILURE,
    payload: { error }
})

export const FETCH_FOAEL_BEGIN = 'FETCH_FOAEL_BEGIN'
export const FETCH_FOAEL_SUCCESS = 'FETCH_FOAEL_SUCCESS'
export const FETCH_FOAEL_FAILURE = 'FETCH_FOAEL_FAILURE'

export const fetchFoAelBegin = () => ({
    type: FETCH_FOAEL_BEGIN
})

export const fetchFoAelSuccess = data => ({
    type: FETCH_FOAEL_SUCCESS,
    payload: { data }
})

export const fetchFoAelFailure = error => ({
    type: FETCH_FOAEL_FAILURE,
    payload: { error }
})

export const FETCH_FONUPURPD_BEGIN = 'FETCH_FONUPURPD_BEGIN'
export const FETCH_FONUPURPD_SUCCESS = 'FETCH_FONUPURPD_SUCCESS'
export const FETCH_FONUPURPD_FAILURE = 'FETCH_FONUPURPD_FAILURE'

export const fetchFoNupurPdBegin = () => ({
    type: FETCH_FONUPURPD_BEGIN
})

export const fetchFoNupurPdSuccess = data => ({
    type: FETCH_FONUPURPD_SUCCESS,
    payload: { data }
})

export const fetchFoNupurPdFailure = error => ({
    type: FETCH_FONUPURPD_FAILURE,
    payload: { error }
})

export const FETCH_BSEMAPPING_BEGIN = 'FETCH_BSEMAPPING_BEGIN'
export const FETCH_BSEMAPPING_SUCCESS = 'FETCH_BSEMAPPING_SUCCESS'
export const FETCH_BSEMAPPING_FAILURE = 'FETCH_BSEMAPPING_FAILURE'

export const fetchBseMappingBegin = () => ({
    type: FETCH_BSEMAPPING_BEGIN
})

export const fetchBseMappingSuccess = data => ({
    type: FETCH_BSEMAPPING_SUCCESS,
    payload: { data }
})

export const fetchBseMappingFailure = error => ({
    type: FETCH_BSEMAPPING_FAILURE,
    payload: { error }
})

export const FETCH_BSEMAPPINGLASTDATE_BEGIN = 'FETCH_BSEMAPPINGLASTDATE_BEGIN'
export const FETCH_BSEMAPPINGLASTDATE_SUCCESS = 'FETCH_BSEMAPPINGLASTDATE_SUCCESS'
export const FETCH_BSEMAPPINGLASTDATE_FAILURE = 'FETCH_BSEMAPPINGLASTDATE_FAILURE'

export const fetchBseMappingLastDateBegin = () => ({
    type: FETCH_BSEMAPPINGLASTDATE_BEGIN
})

export const fetchBseMappingLastDateSuccess = data => ({
    type: FETCH_BSEMAPPINGLASTDATE_SUCCESS,
    payload: { data }
})

export const fetchBseMappingLastDateFailure = error => ({
    type: FETCH_BSEMAPPINGLASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_SECURITYCOST_BEGIN = 'FETCH_SECURITYCOST_BEGIN'
export const FETCH_SECURITYCOST_SUCCESS = 'FETCH_SECURITYCOST_SUCCESS'
export const FETCH_SECURITYCOST_FAILURE = 'FETCH_SECURITYCOST_FAILURE'

export const fetchSecurityCostBegin = () => ({
    type: FETCH_SECURITYCOST_BEGIN
})

export const fetchSecurityCostSuccess = data => ({
    type: FETCH_SECURITYCOST_SUCCESS,
    payload: { data }
})

export const fetchSecurityCostFailure = error => ({
    type: FETCH_SECURITYCOST_FAILURE,
    payload: { error }
})

export const FETCH_APPROVEDSECURITY_BEGIN = 'FETCH_APPROVEDSECURITY_BEGIN'
export const FETCH_APPROVEDSECURITY_SUCCESS = 'FETCH_APPROVEDSECURITY_SUCCESS'
export const FETCH_APPROVEDSECURITY_FAILURE = 'FETCH_APPROVEDSECURITY_FAILURE'

export const fetchApprovedSecurityBegin = () => ({
    type: FETCH_APPROVEDSECURITY_BEGIN
})

export const fetchApprovedSecuritySuccess = data => ({
    type: FETCH_APPROVEDSECURITY_SUCCESS,
    payload: { data }
})

export const fetchApprovedSecurityFailure = error => ({
    type: FETCH_APPROVEDSECURITY_FAILURE,
    payload: { error }
})

export const FETCH_SURVEILENCESECURITY_BEGIN = 'FETCH_SURVEILENCESECURITY_BEGIN'
export const FETCH_SURVEILENCESECURITY_SUCCESS = 'FETCH_SURVEILENCESECURITY_SUCCESS'
export const FETCH_SURVEILENCESECURITY_FAILURE = 'FETCH_SURVEILENCESECURITY_FAILURE'

export const fetchSurveilenceSecurityBegin = () => ({
    type: FETCH_SURVEILENCESECURITY_BEGIN
})

export const fetchSurveilenceSecuritySuccess = data => ({
    type: FETCH_SURVEILENCESECURITY_SUCCESS,
    payload: { data }
})

export const fetchSurveilenceSecurityFailure = error => ({
    type: FETCH_SURVEILENCESECURITY_FAILURE,
    payload: { error }
})

export const FETCH_COMPANYLIST_BEGIN = 'FETCH_COMPANYLIST_BEGIN'
export const FETCH_COMPANYLIST_SUCCESS = 'FETCH_COMPANYLIST_SUCCESS'
export const FETCH_COMPANYLIST_FAILURE = 'FETCH_COMPANYLIST_FAILURE'

export const fetchCompanyListBegin = () => ({
    type: FETCH_COMPANYLIST_BEGIN
})

export const fetchCompanyListSuccess = data => ({
    type: FETCH_COMPANYLIST_SUCCESS,
    payload: { data }
})

export const fetchCompanyListFailure = error => ({
    type: FETCH_COMPANYLIST_FAILURE,
    payload: { error }
})

export const FETCH_CROSSCURRENCY_BEGIN = 'FETCH_CROSSCURRENCY_BEGIN'
export const FETCH_CROSSCURRENCY_SUCCESS = 'FETCH_CROSSCURRENCY_SUCCESS'
export const FETCH_CROSSCURRENCY_FAILURE = 'FETCH_CROSSCURRENCY_FAILURE'

export const fetchCrossCurrencyBegin = () => ({
    type: FETCH_CROSSCURRENCY_BEGIN
})

export const fetchCrossCurrencySuccess = data => ({
    type: FETCH_CROSSCURRENCY_SUCCESS,
    payload: { data }
})

export const fetchCrossCurrencyFailure = error => ({
    type: FETCH_CROSSCURRENCY_FAILURE,
    payload: { error }
})

export const FETCH_LIVECOLLATRAL_BEGIN = 'FETCH_LIVECOLLATRAL_BEGIN'
export const FETCH_LIVECOLLATRAL_SUCCESS = 'FETCH_LIVECOLLATRAL_SUCCESS'
export const FETCH_LIVECOLLATRAL_FAILURE = 'FETCH_LIVECOLLATRAL_FAILURE'

export const fetchLiveCollatralBegin = () => ({
    type: FETCH_LIVECOLLATRAL_BEGIN
})

export const fetchLiveCollatralSuccess = data => ({
    type: FETCH_LIVECOLLATRAL_SUCCESS,
    payload: { data }
})

export const fetchLiveCollatralFailure = error => ({
    type: FETCH_LIVECOLLATRAL_FAILURE,
    payload: { error }
})

export const FETCH_BSESECURITY_BEGIN = 'FETCH_BSESECURITY_BEGIN'
export const FETCH_BSESECURITY_SUCCESS = 'FETCH_BSESECURITY_SUCCESS'
export const FETCH_BSESECURITY_FAILURE = 'FETCH_BSESECURITY_FAILURE'

export const fetchBseSecurityBegin = () => ({
    type: FETCH_BSESECURITY_BEGIN
})

export const fetchBseSecuritySuccess = data => ({
    type: FETCH_BSESECURITY_SUCCESS,
    payload: { data }
})

export const fetchBseSecurityFailure = error => ({
    type: FETCH_BSESECURITY_FAILURE,
    payload: { error }
})

export const FETCH_LASTDATE_BEGIN = 'FETCH_LASTDATE_BEGIN'
export const FETCH_LASTDATE_SUCCESS = 'FETCH_LASTDATE_SUCCESS'
export const FETCH_LASTDATE_FAILURE = 'FETCH_LASTDATE_FAILURE'

export const fetchLastDateBegin = () => ({
    type: FETCH_LASTDATE_BEGIN
})

export const fetchLastDateSuccess = data => ({
    type: FETCH_LASTDATE_SUCCESS,
    payload: { data }
})

export const fetchLastDateFailure = error => ({
    type: FETCH_LASTDATE_FAILURE,
    payload: { error }
})

export const FETCH_MEMBERFOMARGIN_BEGIN = 'FETCH_MEMBERFOMARGIN_BEGIN'
export const FETCH_MEMBERFOMARGIN_SUCCESS = 'FETCH_MEMBERFOMARGIN_SUCCESS'
export const FETCH_MEMBERFOMARGIN_FAILURE = 'FETCH_MEMBERFOMARGIN_FAILURE'

export const fetchMemberFoMarginBegin = () => ({
    type: FETCH_MEMBERFOMARGIN_BEGIN
})

export const fetchMemberFoMarginSuccess = data => ({
    type: FETCH_MEMBERFOMARGIN_SUCCESS,
    payload: { data }
})

export const fetchMemberFoMarginFailure = error => ({
    type: FETCH_MEMBERFOMARGIN_FAILURE,
    payload: { error }
})

export const FETCH_CLIENTFOMARGIN_BEGIN = 'FETCH_CLIENTFOMARGIN_BEGIN'
export const FETCH_CLIENTFOMARGIN_SUCCESS = 'FETCH_CLIENTFOMARGIN_SUCCESS'
export const FETCH_CLIENTFOMARGIN_FAILURE = 'FETCH_CLIENTFOMARGIN_FAILURE'

export const fetchClientFoMarginBegin = () => ({
    type: FETCH_CLIENTFOMARGIN_BEGIN
})

export const fetchClientFoMarginSuccess = data => ({
    type: FETCH_CLIENTFOMARGIN_SUCCESS,
    payload: { data }
})

export const fetchClientFoMarginFailure = error => ({
    type: FETCH_CLIENTFOMARGIN_FAILURE,
    payload: { error }
})

export const FETCH_MEMBERCMMARGIN_BEGIN = 'FETCH_MEMBERCMMARGIN_BEGIN'
export const FETCH_MEMBERCMMARGIN_SUCCESS = 'FETCH_MEMBERCMMARGIN_SUCCESS'
export const FETCH_MEMBERCMMARGIN_FAILURE = 'FETCH_MEMBERCMMARGIN_FAILURE'

export const fetchMemberCmMarginBegin = () => ({
    type: FETCH_MEMBERCMMARGIN_BEGIN
})

export const fetchMemberCmMarginSuccess = data => ({
    type: FETCH_MEMBERCMMARGIN_SUCCESS,
    payload: { data }
})

export const fetchMemberCmMarginFailure = error => ({
    type: FETCH_MEMBERCMMARGIN_FAILURE,
    payload: { error }
})

export const FETCH_MEMBERCMNEWMARGIN_BEGIN = 'FETCH_MEMBERCMNEWMARGIN_BEGIN'
export const FETCH_MEMBERCMNEWMARGIN_SUCCESS = 'FETCH_MEMBERCMNEWMARGIN_SUCCESS'
export const FETCH_MEMBERCMNEWMARGIN_FAILURE = 'FETCH_MEMBERCMNEWMARGIN_FAILURE'

export const fetchMemberCmNewMarginBegin = () => ({
    type: FETCH_MEMBERCMNEWMARGIN_BEGIN
})

export const fetchMemberCmNewMarginSuccess = data => ({
    type: FETCH_MEMBERCMNEWMARGIN_SUCCESS,
    payload: { data }
})

export const fetchMemberCmNewMarginFailure = error => ({
    type: FETCH_MEMBERCMNEWMARGIN_FAILURE,
    payload: { error }
})

export const FETCH_CLIENTCMMARGIN_BEGIN = 'FETCH_CLIENTCMMARGIN_BEGIN'
export const FETCH_CLIENTCMMARGIN_SUCCESS = 'FETCH_CLIENTCMMARGIN_SUCCESS'
export const FETCH_CLIENTCMMARGIN_FAILURE = 'FETCH_CLIENTCMMARGIN_FAILURE'

export const fetchClientCmMarginBegin = () => ({
    type: FETCH_CLIENTCMMARGIN_BEGIN
})

export const fetchClientCmMarginSuccess = data => ({
    type: FETCH_CLIENTCMMARGIN_SUCCESS,
    payload: { data }
})

export const fetchClientCmMarginFailure = error => ({
    type: FETCH_CLIENTCMMARGIN_FAILURE,
    payload: { error }
})

export const FETCH_MEMBERCDMARGIN_BEGIN = 'FETCH_MEMBERCDMARGIN_BEGIN'
export const FETCH_MEMBERCDMARGIN_SUCCESS = 'FETCH_MEMBERCDMARGIN_SUCCESS'
export const FETCH_MEMBERCDMARGIN_FAILURE = 'FETCH_MEMBERCDMARGIN_FAILURE'

export const fetchMemberCdMarginBegin = () => ({
    type: FETCH_MEMBERCDMARGIN_BEGIN
})

export const fetchMemberCdMarginSuccess = data => ({
    type: FETCH_MEMBERCDMARGIN_SUCCESS,
    payload: { data }
})

export const fetchMemberCdMarginFailure = error => ({
    type: FETCH_MEMBERCDMARGIN_FAILURE,
    payload: { error }
})

export const FETCH_CLIENTCDMARGIN_BEGIN = 'FETCH_CLIENTCDMARGIN_BEGIN'
export const FETCH_CLIENTCDMARGIN_SUCCESS = 'FETCH_CLIENTCDMARGIN_SUCCESS'
export const FETCH_CLIENTCDMARGIN_FAILURE = 'FETCH_CLIENTCDMARGIN_FAILURE'

export const fetchClientCdMarginBegin = () => ({
    type: FETCH_CLIENTCDMARGIN_BEGIN
})

export const fetchClientCdMarginSuccess = data => ({
    type: FETCH_CLIENTCDMARGIN_SUCCESS,
    payload: { data }
})

export const fetchClientCdMarginFailure = error => ({
    type: FETCH_CLIENTCDMARGIN_FAILURE,
    payload: { error }
})

export const FETCH_LIVECOLLATRALCLIENTDATA_BEGIN = 'FETCH_LIVECOLLATRALCLIENTDATA_BEGIN'
export const FETCH_LIVECOLLATRALCLIENTDATA_SUCCESS = 'FETCH_LIVECOLLATRALCLIENTDATA_SUCCESS'
export const FETCH_LIVECOLLATRALCLIENTDATA_FAILURE = 'FETCH_LIVECOLLATRALCLIENTDATA_FAILURE'

export const fetchLiveCollatralClientDataBegin = () => ({
    type: FETCH_LIVECOLLATRALCLIENTDATA_BEGIN
})

export const fetchLiveCollatralClientDataSuccess = data => ({
    type: FETCH_LIVECOLLATRALCLIENTDATA_SUCCESS,
    payload: { data }
})

export const fetchLiveCollatralClientDataFailure = error => ({
    type: FETCH_LIVECOLLATRALCLIENTDATA_FAILURE,
    payload: { error }
})