
import * as ACTIONS from '../../actions/reports'

const initialState = {
    PackageTags: [],
    loading: false,
    error: ''
}

const PackageTags = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_PACKAGE_TAGS_BEGIN:
            return {
                ...state,
                PackageTags: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_PACKAGE_TAGS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                PackageTags: action.payload.data
            }

        case ACTIONS.FETCH_PACKAGE_TAGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default PackageTags;