import * as ACTIONS from '../../actions/reports'

const initialState = {
    loading: false,
    error: '',
    ncborder: {}
}

const ncborder = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.ADD_NCBORDER_BEGIN:
            return {
                ...state,
                loading: true,
                error: '',
                ncborder: []
            }

        case ACTIONS.ADD_NCBORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                ncborder: action.payload.ipo
            }

        case ACTIONS.ADD_NCBORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default ncborder