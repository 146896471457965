
import * as ACTIONS from '../../actions/reports'

const initialState = {
    Bookmarked: [],
    loading: false,
    error: ''
}

const Bookmarked = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_BOOKMARKED_BEGIN:
            return {
                ...state,
                Bookmarked: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_BOOKMARKED_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                Bookmarked: action.payload.data
            }

        case ACTIONS.FETCH_BOOKMARKED_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default Bookmarked;