import * as ACTIONS from '../../actions/rms'

const initialState = {
    clientcode: [],
    symphonyclientcode: [],
    symphonylastdate: [],
    foBhavcopyLastDate: [],
    foAelLastDate: [],
    foSecBanLastDate: [],
    foQtyFreezeLastDate: [],
    appsecLastDate: [],
    varrateLastDate: [],
    auctionLastDate: [],
    nupurbcLastDate: [],
    nupurpdLastDate: [],
    nupurbhLastDate: [],
    nupurhlLastDate: [],
    nupursmeLastDate: [],
    appsecdata: [],
    varratedata: [],
    auctiondata: [],
    cmnupurpddata: [],
    cmnupurbcdata: [],
    cmnupurbhdata: [],
    cmnupurhldata: [],
    cmnupursmedata: [],
    foqtyfreezedata: [],
    fobhavcopydata: [],
    foaeldata: [],
    fonupurpddata: [],
    bsemappingdata: [],
    bsemappingLastDate: [],
    securitycostdata: [],
    approvedsecuritydata: [],
    surveilencesecuritydata: [],
    companylistdata: [],
    crosscurrencydata: [],
    livecollatraldata: [],
    bsesecuritydata: [],
    lastdatedata: [],
    memberfomargindata: [],
    membercmmargindata: [],
    membercdmargindata: [],
    membercmnewmargindata: [],
    livecollatralclientwisedata: [],
    clientcdmargindata: [],
    clientcmmargindata: [],
    clientfomargindata: [],
    loading: false,
    error: ''
}

const rmsclientcode = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_RMSCLIENTCODE_BEGIN:
            return {
                ...state,
                clientcode: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSCLIENTCODE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                clientcode: action.payload.data
            }

        case ACTIONS.FETCH_RMSCLIENTCODE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_RMSSYMPHONYCLIENTCODE_BEGIN:
            return {
                ...state,
                symphonyclientcode: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSSYMPHONYCLIENTCODE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                symphonyclientcode: action.payload.data
            }

        case ACTIONS.FETCH_RMSSYMPHONYCLIENTCODE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_RMSSYMPHONYLASTDATE_BEGIN:
            return {
                ...state,
                symphonylastdate: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSSYMPHONYLASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                symphonylastdate: action.payload.data
            }

        case ACTIONS.FETCH_RMSSYMPHONYLASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_RMSFOBHAVCOPYLASTDATE_BEGIN:
            return {
                ...state,
                foBhavcopyLastDate: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSFOBHAVCOPYLASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                foBhavcopyLastDate: action.payload.data
            }

        case ACTIONS.FETCH_RMSFOBHAVCOPYLASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_RMSFOAELLASTDATE_BEGIN:
            return {
                ...state,
                foAelLastDate: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSFOAELLASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                foAelLastDate: action.payload.data
            }

        case ACTIONS.FETCH_RMSFOAELLASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_RMSFOSECBANLASTDATE_BEGIN:
            return {
                ...state,
                foSecBanLastDate: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSFOSECBANLASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                foSecBanLastDate: action.payload.data
            }

        case ACTIONS.FETCH_RMSFOSECBANLASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_RMSFOQTYFREEZELASTDATE_BEGIN:
            return {
                ...state,
                foQtyFreezeLastDate: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSFOQTYFREEZELASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                foQtyFreezeLastDate: action.payload.data
            }

        case ACTIONS.FETCH_RMSFOQTYFREEZELASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_RMSAPPSECLASTDATE_BEGIN:
            return {
                ...state,
                appsecLastDate: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSAPPSECLASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                appsecLastDate: action.payload.data
            }

        case ACTIONS.FETCH_RMSAPPSECLASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_RMSVARRATELASTDATE_BEGIN:
            return {
                ...state,
                varrateLastDate: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSVARRATELASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                varrateLastDate: action.payload.data
            }

        case ACTIONS.FETCH_RMSVARRATELASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_RMSAUCTIONLASTDATE_BEGIN:
            return {
                ...state,
                auctionLastDate: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSAUCTIONLASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                auctionLastDate: action.payload.data
            }

        case ACTIONS.FETCH_RMSAUCTIONLASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_RMSNUPURBCLASTDATE_BEGIN:
            return {
                ...state,
                nupurbcLastDate: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSNUPURBCLASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                nupurbcLastDate: action.payload.data
            }

        case ACTIONS.FETCH_RMSNUPURBCLASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_RMSNUPURPDLASTDATE_BEGIN:
            return {
                ...state,
                nupurpdLastDate: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSNUPURPDLASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                nupurpdLastDate: action.payload.data
            }

        case ACTIONS.FETCH_RMSNUPURPDLASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_RMSNUPURBHLASTDATE_BEGIN:
            return {
                ...state,
                nupurbhLastDate: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSNUPURBHLASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                nupurbhLastDate: action.payload.data
            }

        case ACTIONS.FETCH_RMSNUPURBHLASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_RMSNUPURHLLASTDATE_BEGIN:
            return {
                ...state,
                nupurhlLastDate: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSNUPURHLLASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                nupurhlLastDate: action.payload.data
            }

        case ACTIONS.FETCH_RMSNUPURHLLASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_RMSNUPURSMELASTDATE_BEGIN:
            return {
                ...state,
                nupursmeLastDate: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_RMSNUPURSMELASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                nupursmeLastDate: action.payload.data
            }

        case ACTIONS.FETCH_RMSNUPURSMELASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_APPSEC_BEGIN:
            return {
                ...state,
                appsecdata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_APPSEC_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                appsecdata: action.payload.data
            }

        case ACTIONS.FETCH_APPSEC_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_VARRATE_BEGIN:
            return {
                ...state,
                varratedata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_VARRATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                varratedata: action.payload.data
            }

        case ACTIONS.FETCH_VARRATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_AUCTION_BEGIN:
            return {
                ...state,
                auctiondata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_AUCTION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                auctiondata: action.payload.data
            }

        case ACTIONS.FETCH_AUCTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_CMNUPURPD_BEGIN:
            return {
                ...state,
                cmnupurpddata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_CMNUPURPD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                cmnupurpddata: action.payload.data
            }

        case ACTIONS.FETCH_CMNUPURPD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_CMNUPURBC_BEGIN:
            return {
                ...state,
                cmnupurbcdata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_CMNUPURBC_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                cmnupurbcdata: action.payload.data
            }

        case ACTIONS.FETCH_CMNUPURBC_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_CMNUPURBH_BEGIN:
            return {
                ...state,
                cmnupurbhdata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_CMNUPURBH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                cmnupurbhdata: action.payload.data
            }

        case ACTIONS.FETCH_CMNUPURBC_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_CMNUPURHL_BEGIN:
            return {
                ...state,
                cmnupurhldata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_CMNUPURHL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                cmnupurhldata: action.payload.data
            }

        case ACTIONS.FETCH_CMNUPURHL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_CMNUPURSME_BEGIN:
            return {
                ...state,
                cmnupursmedata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_CMNUPURSME_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                cmnupursmedata: action.payload.data
            }

        case ACTIONS.FETCH_CMNUPURSME_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_FOQTYFREEZE_BEGIN:
            return {
                ...state,
                foqtyfreezedata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_FOQTYFREEZE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                foqtyfreezedata: action.payload.data
            }

        case ACTIONS.FETCH_FOQTYFREEZE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_FOBHAVCOPY_BEGIN:
            return {
                ...state,
                fobhavcopydata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_FOBHAVCOPY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                fobhavcopydata: action.payload.data
            }

        case ACTIONS.FETCH_FOBHAVCOPY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_FOAEL_BEGIN:
            return {
                ...state,
                foaeldata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_FOAEL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                foaeldata: action.payload.data
            }

        case ACTIONS.FETCH_FOAEL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_FONUPURPD_BEGIN:
            return {
                ...state,
                fonupurpddata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_FONUPURPD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                fonupurpddata: action.payload.data
            }

        case ACTIONS.FETCH_FONUPURPD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_BSEMAPPING_BEGIN:
            return {
                ...state,
                bsemappingdata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_BSEMAPPING_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                bsemappingdata: action.payload.data
            }

        case ACTIONS.FETCH_BSEMAPPING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_BSEMAPPINGLASTDATE_BEGIN:
            return {
                ...state,
                bsemappingLastDate: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_BSEMAPPINGLASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                bsemappingLastDate: action.payload.data
            }

        case ACTIONS.FETCH_BSEMAPPINGLASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_SECURITYCOST_BEGIN:
            return {
                ...state,
                securitycostdata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_SECURITYCOST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                securitycostdata: action.payload.data
            }

        case ACTIONS.FETCH_SECURITYCOST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_APPROVEDSECURITY_BEGIN:
            return {
                ...state,
                approvedsecuritydata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_APPROVEDSECURITY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                approvedsecuritydata: action.payload.data
            }

        case ACTIONS.FETCH_APPROVEDSECURITY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_SURVEILENCESECURITY_BEGIN:
            return {
                ...state,
                surveilencesecuritydata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_SURVEILENCESECURITY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                surveilencesecuritydata: action.payload.data
            }

        case ACTIONS.FETCH_SURVEILENCESECURITY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_COMPANYLIST_BEGIN:
            return {
                ...state,
                companylistdata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_COMPANYLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                companylistdata: action.payload.data
            }

        case ACTIONS.FETCH_COMPANYLIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_CROSSCURRENCY_BEGIN:
            return {
                ...state,
                crosscurrencydata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_CROSSCURRENCY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                crosscurrencydata: action.payload.data
            }

        case ACTIONS.FETCH_CROSSCURRENCY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_LIVECOLLATRAL_BEGIN:
            return {
                ...state,
                livecollatraldata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_LIVECOLLATRAL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                livecollatraldata: action.payload.data
            }

        case ACTIONS.FETCH_LIVECOLLATRAL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_BSESECURITY_BEGIN:
            return {
                ...state,
                bsesecuritydata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_BSESECURITY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                bsesecuritydata: action.payload.data
            }

        case ACTIONS.FETCH_BSESECURITY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_LASTDATE_BEGIN:
            return {
                ...state,
                lastdatedata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_LASTDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                lastdatedata: action.payload.data
            }

        case ACTIONS.FETCH_LASTDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_MEMBERFOMARGIN_BEGIN:
            return {
                ...state,
                memberfomargindata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_MEMBERFOMARGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                memberfomargindata: action.payload.data
            }

        case ACTIONS.FETCH_MEMBERFOMARGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_CLIENTFOMARGIN_BEGIN:
            return {
                ...state,
                clientfomargindata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_CLIENTFOMARGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                clientfomargindata: action.payload.data
            }

        case ACTIONS.FETCH_CLIENTFOMARGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_MEMBERCMMARGIN_BEGIN:
            return {
                ...state,
                membercmmargindata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_MEMBERCMMARGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                membercmmargindata: action.payload.data
            }

        case ACTIONS.FETCH_MEMBERCMMARGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_MEMBERCMNEWMARGIN_BEGIN:
            return {
                ...state,
                membercmnewmargindata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_MEMBERCMNEWMARGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                membercmnewmargindata: action.payload.data
            }

        case ACTIONS.FETCH_MEMBERCMNEWMARGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_CLIENTCMMARGIN_BEGIN:
            return {
                ...state,
                clientcmmargindata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_CLIENTCMMARGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                clientcmmargindata: action.payload.data
            }

        case ACTIONS.FETCH_CLIENTCMMARGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_MEMBERCDMARGIN_BEGIN:
            return {
                ...state,
                membercdmargindata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_MEMBERCDMARGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                membercdmargindata: action.payload.data
            }

        case ACTIONS.FETCH_MEMBERCDMARGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_CLIENTCDMARGIN_BEGIN:
            return {
                ...state,
                clientcdmargindata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_CLIENTCDMARGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                clientcdmargindata: action.payload.data
            }

        case ACTIONS.FETCH_CLIENTCDMARGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_LIVECOLLATRALCLIENTDATA_BEGIN:
            return {
                ...state,
                livecollatralclientwisedata: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_LIVECOLLATRALCLIENTDATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                livecollatralclientwisedata: action.payload.data
            }

        case ACTIONS.FETCH_LIVECOLLATRALCLIENTDATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default rmsclientcode;