import * as ACTIONS from '../../actions/menu-master'

const initialState = {
    menus: [],
    primarymenus: [],
    loading: false,
    error: '',
}

const menu = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_MENUMASTER_BEGIN:
            return {
                ...state,
                menus: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_MENUMASTER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                menus: action.payload.data
            }

        case ACTIONS.FETCH_MENUMASTER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_PRIMARYMENU_BEGIN:
            return {
                ...state,
                primarymenus: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_PRIMARYMENU_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                primarymenus: action.payload.data
            }

        case ACTIONS.FETCH_PRIMARYMENU_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default menu;