
import * as ACTIONS from '../../actions/reports/index';

const initialState = {
    CourierType: [],
    loading: false,
    error: ''
}

const CourierType = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_COURIER_TYPE_BEGIN:
            return {
                ...state,
                CourierType: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_COURIER_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                CourierType: action.payload.data
            }

        case ACTIONS.FETCH_COURIER_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default CourierType;