
import * as ACTIONS from '../../actions/reports'

const initialState = {
    EmployeeModule: [],
    loading: false,
    error: ''
}

const EmployeeModule = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_EMMPLOYEE_MODULE_BEGIN:
            return {
                ...state,
                EmployeeModule: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_EMMPLOYEE_MODULE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                EmployeeModule: action.payload.data
            }

        case ACTIONS.FETCH_EMMPLOYEE_MODULE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default EmployeeModule;