
import * as ACTIONS from '../../actions/reports'

const initialState = {
    ReminderNotification: [],
    loading: false,
    error: ''
}

const ReminderNotification = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_REMINDER_NOTIFICATION_BEGIN:
            return {
                ...state,
                AccountOffers: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_REMINDER_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                ReminderNotification: action.payload.data
            }

        case ACTIONS.FETCH_REMINDER_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default ReminderNotification;