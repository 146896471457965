
import * as ACTIONS from '../../actions/reports'

const initialState = {
    KYCRemark: [],
    loading: false,
    error: ''
}

const KYCRemark = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_KYC_REMARK_BEGIN:
            return {
                ...state,
                KYCRemark: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_KYC_REMARK_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                KYCRemark: action.payload.data
            }

        case ACTIONS.FETCH_KYC_REMARK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default KYCRemark;