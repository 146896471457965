//clients
export const FETCH_QUATERLYSETTLEMENT_BEGIN = 'FETCH_QUATERLYSETTLEMENT_BEGIN'
export const FETCH_QUATERLYSETTLEMENT_SUCCESS = 'FETCH_QUATERLYSETTLEMENT_SUCCESS'
export const FETCH_QUATERLYSETTLEMENT_FAILURE = 'FETCH_QUATERLYSETTLEMENT_FAILURE'

export const fetchQuaterlySettlementBegin = () => ({
    type: FETCH_QUATERLYSETTLEMENT_BEGIN
})

export const fetchQuaterlySettlementSuccess = data => ({
    type: FETCH_QUATERLYSETTLEMENT_SUCCESS,
    payload: { data }
})

export const fetchQuaterlySettlementFailure = error => ({
    type: FETCH_QUATERLYSETTLEMENT_FAILURE,
    payload: { error }
})

export const FETCH_EARLYPAYIN_BEGIN = 'FETCH_EARLYPAYIN_BEGIN'
export const FETCH_EARLYPAYIN_SUCCESS = 'FETCH_EARLYPAYIN_SUCCESS'
export const FETCH_EARLYPAYIN_FAILURE = 'FETCH_EARLYPAYIN_FAILURE'

export const fetchEarlyPayinBegin = () => ({
    type: FETCH_EARLYPAYIN_BEGIN
})

export const fetchEarlyPayinSuccess = data => ({
    type: FETCH_EARLYPAYIN_SUCCESS,
    payload: { data }
})

export const fetchEarlyPayinFailure = error => ({
    type: FETCH_EARLYPAYIN_FAILURE,
    payload: { error }
})

export const FETCH_PHYSICAL_BEGIN = 'FETCH_PHYSICAL_BEGIN'
export const FETCH_PHYSICAL_SUCCESS = 'FETCH_PHYSICAL_SUCCESS'
export const FETCH_PHYSICAL_FAILURE = 'FETCH_PHYSICAL_FAILURE'

export const fetchPhysicalBegin = () => ({
    type: FETCH_PHYSICAL_BEGIN
})

export const fetchPhysicalSuccess = data => ({
    type: FETCH_PHYSICAL_SUCCESS,
    payload: { data }
})

export const fetchPhysicalFailure = error => ({
    type: FETCH_PHYSICAL_FAILURE,
    payload: { error }
})

export const FETCH_PEAKFILE_BEGIN = 'FETCH_PEAKFILE_BEGIN'
export const FETCH_PEAKFILE_SUCCESS = 'FETCH_PEAKFILE_SUCCESS'
export const FETCH_PEAKFILE_FAILURE = 'FETCH_PEAKFILE_FAILURE'

export const fetchPeakFileBegin = () => ({
    type: FETCH_PEAKFILE_BEGIN
})

export const fetchPeakFileSuccess = data => ({
    type: FETCH_PEAKFILE_SUCCESS,
    payload: { data }
})

export const fetchPeakFileFailure = error => ({
    type: FETCH_PEAKFILE_FAILURE,
    payload: { error }
})

export const FETCH_TECHEXCELFILE_SUCCESS = 'FETCH_TECHEXCELFILE_SUCCESS'
export const fetchTechExcelFileSuccess = data => ({
    type: FETCH_TECHEXCELFILE_SUCCESS,
    payload: { data }
})