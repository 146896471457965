
import * as ACTIONS from '../../actions/reports/index';

const initialState = {
    Leave: [],
    loading: false,
    error: ''
}

const Leave = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_LEAVE_BEGIN:
            return {
                ...state,
                Leave: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_LEAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                Leave: action.payload.data
            }

        case ACTIONS.FETCH_LEAVE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default Leave;