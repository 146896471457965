
//fetch branches
export const FETCH_BRANCHES_BEGIN = 'FETCH_BRANCHES_BEGIN'
export const FETCH_BRANCHES_SUCCESS = 'FETCH_BRANCHES_SUCCESS'
export const FETCH_BRANCHES_FAILURE = 'FETCH_BRANCHES_FAILURE'

export const fetchBranchesBegin = () => ({
    type: FETCH_BRANCHES_BEGIN
})

export const fetchBranchesSuccess = branches => ({
    type: FETCH_BRANCHES_SUCCESS,
    payload: { branches }
})

export const fetchBranchesFailure = error => ({
    type: FETCH_BRANCHES_FAILURE,
    payload: { error }
})