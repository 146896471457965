
import * as ACTIONS from '../../actions/reports'

const initialState = {
    city: [],
    loading: false,
    error: ''
}

const city = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.CITY_BEGIN:
            return {
                ...state,
                city: [],
                loading: true,
                error: ''
            }

        case ACTIONS.CITY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                city: action.payload.data
            }

        case ACTIONS.CITY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default city;