
import * as ACTIONS from '../../actions/mail'
import _ from 'lodash'

const initialState = {
    mails: [],
    allmails: [],
    loading: false,
    error: '',
    selectedMails: [],
    currentMail: null,
    sendMail: null,
    mailReadCount: '',
    notApprovedCount: '',
    allemailaddress: [],
}

const mail = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_MAIL_BEGIN:
            return {
                ...state,
                mails: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_MAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                mails: action.payload.mails
            }

        case ACTIONS.FETCH_MAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.SET_SELECTED_MAIL:
            return {
                ...state,
                currentMail: _.filter(state.mails.mails, { MessageId: action.payload.mail })[0]
            }

        case ACTIONS.RESET_SELECT_MAILS:
            return {
                ...state,
                selectedMails: []
            }

        case ACTIONS.SELECT_MAIL:
            // ** Select mails
            const selectedMails = state.selectedMails
            if (!selectedMails.includes(action.payload.id)) {
                selectedMails.push(action.payload.id)
            } else {
                selectedMails.splice(selectedMails.indexOf(action.payload.id), 1)
            }
            return { ...state, selectedMails }

        case ACTIONS.SELECT_ALL_MAIL:
            // ** Select all mails
            const selectAllMails = []
            if (action.payload.val) {
                selectAllMails.length = 0
                state.mails.mails.forEach(mail => selectAllMails.push(mail.MessageId))
            } else {
                selectAllMails.length = 0
            }
            return { ...state, selectedMails: selectAllMails }

        case ACTIONS.SEND_MAIL_BEGIN:
            return {
                ...state,
                sendMail: null,
                loading: true,
                error: ''
            }

        case ACTIONS.SEND_MAIL_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case ACTIONS.SEND_MAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_ALL_MAIL_BEGIN:
            return {
                ...state,
                allmails: [],
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_ALL_MAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                allmails: action.payload.mails
            }

        case ACTIONS.FETCH_ALL_MAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_MAIL_READ_COUNT_BEGIN:
            return {
                ...state,
                mailReadCount: '',
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_MAIL_READ_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                mailReadCount: action.payload.mails.mails,
                notApprovedCount: action.payload.mails.notapprovedcount
            }

        case ACTIONS.FETCH_MAIL_READ_COUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case ACTIONS.FETCH_ALL_EMAIL_ADDRESS_BEGIN:
            return {
                ...state,
                allemailaddress: '',
                loading: true,
                error: ''
            }

        case ACTIONS.FETCH_ALL_EMAIL_ADDRESS_SUCCESS:            
            return {
                ...state,
                loading: false,
                error: '',
                allemailaddress: action.payload.mails
            }

        case ACTIONS.FETCH_ALL_EMAIL_ADDRESS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default mail;